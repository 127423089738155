import { useEffect, useState } from 'react';

import { breakpoints } from '../styles';

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function useDimensions() {
  const [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    ...windowSize,
    isPhone: () => window.innerWidth < breakpoints.mobileLG,
    isSemitablet: () => window.innerWidth < breakpoints.tabletMD,
    isTablet: () => window.innerWidth < breakpoints.tabletMD,
    isLaptop: () => window.innerWidth < breakpoints.laptop,
    isDesktop: () => window.innerWidth < breakpoints.desktop,
  };
}

export { useDimensions };
