import { useState } from 'react';

import { Locale } from '@/shared/types';

const storageKey = 'mellow:locale';

export const useLocale = () => {
  const storedLocale = localStorage.getItem(storageKey) || 'en-US';
  const [locale, setLocaleState] = useState<Locale>(storedLocale as Locale);

  const setLocale = (value: Locale) => {
    if (value === locale) return;

    setLocaleState(value);
    localStorage.setItem(storageKey, value);
  };

  return { locale, setLocale };
};
