import type { Props as ResourcesProps } from './components/Resources/Resources';

export const resourcesList: ResourcesProps[] = [
  {
    title: 'Legal',
    resources: [
      {
        name: 'Terms of Service',
        href: '/Mellow-Terms-of-Service.pdf',
      },
      {
        name: 'Privacy Notice',
        href: '/Mellow-Privacy-Notice.pdf',
      },
      {
        name: 'Risk Disclosure Statement',
        href: '/Mellow-Risks-Disclosure-Statement.pdf',
      },
    ],
  },
  {
    title: 'Developers',
    resources: [
      {
        name: 'Documentation',
        href: 'https://docs.mellow.finance',
        isExternal: true,
      },
      {
        name: 'GitHub',
        href: '//github.com/mellow-finance',
        isExternal: true,
      },
    ],
  },
  {
    title: 'Community',
    resources: [
      {
        name: 'Twitter',
        href: '//twitter.com/mellowprotocol',
        isExternal: true,
      },
      {
        name: 'Substack',
        href: '//mellowprotocol.substack.com/',
        isExternal: true,
      },
      {
        name: 'Discord',
        href: '//discord.gg/mellow',
        isExternal: true,
      },
    ],
  },
];
