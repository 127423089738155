import { Navigate, Route, Routes } from 'react-router-dom';
import smoothScrollPolyfill from 'smoothscroll-polyfill';

import { RestakeLandingPage } from '@/pages/RestakangLandingPage';

smoothScrollPolyfill.polyfill();

function Landing() {
  return (
    <Routes>
      <Route path="/" element={<RestakeLandingPage />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export { Landing };
