export const collectorAbi = [
  {
    inputs: [
      { internalType: 'address', name: 'wsteth_', type: 'address' },
      { internalType: 'address', name: 'weth_', type: 'address' },
      { internalType: 'address', name: 'steth_', type: 'address' },
      { internalType: 'contract IAggregatorV3', name: '_wstethOracle', type: 'address' },
      { internalType: 'contract IAggregatorV3', name: '_wethToUSDOracle', type: 'address' },
      { internalType: 'address', name: 'admin_', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { inputs: [], name: 'AccessControlBadConfirmation', type: 'error' },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'bytes32', name: 'neededRole', type: 'bytes32' },
    ],
    name: 'AccessControlUnauthorizedAccount',
    type: 'error',
  },
  { inputs: [], name: 'AddressZero', type: 'error' },
  { inputs: [], name: 'Forbidden', type: 'error' },
  { inputs: [], name: 'MathOverflowedMulDiv', type: 'error' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32' },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    inputs: [],
    name: 'ADMIN_DELEGATE_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'D18',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'D9',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'OPERATOR',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'Q96',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'collector_', type: 'address' }],
    name: 'addCollectors',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'address[]', name: 'vaults', type: 'address[]' },
    ],
    name: 'collect',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'address[]', name: 'underlyingTokens', type: 'address[]' },
          { internalType: 'uint256[]', name: 'underlyingAmounts', type: 'uint256[]' },
          { internalType: 'uint8[]', name: 'underlyingTokenDecimals', type: 'uint8[]' },
          { internalType: 'uint128[]', name: 'depositRatiosX96', type: 'uint128[]' },
          { internalType: 'uint128[]', name: 'withdrawalRatiosX96', type: 'uint128[]' },
          { internalType: 'uint256[]', name: 'pricesX96', type: 'uint256[]' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceETH', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceWSTETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceBaseTokenD18', type: 'uint256' },
          { internalType: 'bool', name: 'shouldCloseWithdrawalRequest', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
              { internalType: 'bytes32', name: 'tokensHash', type: 'bytes32' },
              { internalType: 'uint256[]', name: 'minAmounts', type: 'uint256[]' },
              { internalType: 'uint256', name: 'deadline', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct IVault.WithdrawalRequest',
            name: 'withdrawalRequest',
            type: 'tuple',
          },
          { internalType: 'bool', name: 'isDefi', type: 'bool' },
        ],
        internalType: 'struct Collector.Response[]',
        name: 'responses',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'priceX96', type: 'uint256' },
      { internalType: 'address', name: 'token', type: 'address' },
    ],
    name: 'convertBaseTokenPriceToWethPrice',
    outputs: [{ internalType: 'uint256', name: 'wethPriceX96', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'address[]', name: 'underlyingTokens', type: 'address[]' },
          { internalType: 'uint256[]', name: 'underlyingAmounts', type: 'uint256[]' },
          { internalType: 'uint8[]', name: 'underlyingTokenDecimals', type: 'uint8[]' },
          { internalType: 'uint128[]', name: 'depositRatiosX96', type: 'uint128[]' },
          { internalType: 'uint128[]', name: 'withdrawalRatiosX96', type: 'uint128[]' },
          { internalType: 'uint256[]', name: 'pricesX96', type: 'uint256[]' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceETH', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceWSTETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceBaseTokenD18', type: 'uint256' },
          { internalType: 'bool', name: 'shouldCloseWithdrawalRequest', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
              { internalType: 'bytes32', name: 'tokensHash', type: 'bytes32' },
              { internalType: 'uint256[]', name: 'minAmounts', type: 'uint256[]' },
              { internalType: 'uint256', name: 'deadline', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct IVault.WithdrawalRequest',
            name: 'withdrawalRequest',
            type: 'tuple',
          },
          { internalType: 'bool', name: 'isDefi', type: 'bool' },
        ],
        internalType: 'struct Collector.Response[]',
        name: 'responses',
        type: 'tuple[]',
      },
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'convertTokenIntoEth',
    outputs: [{ internalType: 'uint256', name: 'ethAmount', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'convertWethToUSDC',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
      { internalType: 'address', name: 'vault', type: 'address' },
      { internalType: 'address', name: 'user', type: 'address' },
    ],
    name: 'fetchDepositAmounts',
    outputs: [
      {
        components: [
          { internalType: 'bool', name: 'isDepositPossible', type: 'bool' },
          { internalType: 'bool', name: 'isDepositorWhitelisted', type: 'bool' },
          { internalType: 'uint256[]', name: 'ratiosD18', type: 'uint256[]' },
          { internalType: 'address[]', name: 'tokens', type: 'address[]' },
          { internalType: 'uint256', name: 'expectedLpAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'expectedLpAmountUSDC', type: 'uint256' },
          { internalType: 'uint256[]', name: 'expectedAmounts', type: 'uint256[]' },
          { internalType: 'uint256[]', name: 'expectedAmountsUSDC', type: 'uint256[]' },
        ],
        internalType: 'struct Collector.FetchDepositAmountsResponse',
        name: 'r',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'vault', type: 'address' },
      { internalType: 'address', name: 'wrapper', type: 'address' },
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'fetchDepositWrapperParams',
    outputs: [
      { internalType: 'bool', name: 'isDepositPossible', type: 'bool' },
      { internalType: 'bool', name: 'isDepositorWhitelisted', type: 'bool' },
      { internalType: 'bool', name: 'isWhitelistedToken', type: 'bool' },
      { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'depositValueUSDC', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
      { internalType: 'address', name: 'vault', type: 'address' },
    ],
    name: 'fetchWithdrawalAmounts',
    outputs: [
      { internalType: 'uint256[]', name: 'expectedAmounts', type: 'uint256[]' },
      { internalType: 'uint256[]', name: 'expectedAmountsUSDC', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'getRoleMember',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleMemberCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'address[]', name: 'underlyingTokens', type: 'address[]' },
          { internalType: 'uint256[]', name: 'underlyingAmounts', type: 'uint256[]' },
          { internalType: 'uint8[]', name: 'underlyingTokenDecimals', type: 'uint8[]' },
          { internalType: 'uint128[]', name: 'depositRatiosX96', type: 'uint128[]' },
          { internalType: 'uint128[]', name: 'withdrawalRatiosX96', type: 'uint128[]' },
          { internalType: 'uint256[]', name: 'pricesX96', type: 'uint256[]' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceETH', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceWSTETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceBaseTokenD18', type: 'uint256' },
          { internalType: 'bool', name: 'shouldCloseWithdrawalRequest', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
              { internalType: 'bytes32', name: 'tokensHash', type: 'bytes32' },
              { internalType: 'uint256[]', name: 'minAmounts', type: 'uint256[]' },
              { internalType: 'uint256', name: 'deadline', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct IVault.WithdrawalRequest',
            name: 'withdrawalRequest',
            type: 'tuple',
          },
          { internalType: 'bool', name: 'isDefi', type: 'bool' },
        ],
        internalType: 'struct Collector.Response[]',
        name: 'responses',
        type: 'tuple[]',
      },
      { internalType: 'address', name: 'vault', type: 'address' },
    ],
    name: 'getVaultResponse',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'address[]', name: 'underlyingTokens', type: 'address[]' },
          { internalType: 'uint256[]', name: 'underlyingAmounts', type: 'uint256[]' },
          { internalType: 'uint8[]', name: 'underlyingTokenDecimals', type: 'uint8[]' },
          { internalType: 'uint128[]', name: 'depositRatiosX96', type: 'uint128[]' },
          { internalType: 'uint128[]', name: 'withdrawalRatiosX96', type: 'uint128[]' },
          { internalType: 'uint256[]', name: 'pricesX96', type: 'uint256[]' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceETH', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceWSTETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceBaseTokenD18', type: 'uint256' },
          { internalType: 'bool', name: 'shouldCloseWithdrawalRequest', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
              { internalType: 'bytes32', name: 'tokensHash', type: 'bytes32' },
              { internalType: 'uint256[]', name: 'minAmounts', type: 'uint256[]' },
              { internalType: 'uint256', name: 'deadline', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct IVault.WithdrawalRequest',
            name: 'withdrawalRequest',
            type: 'tuple',
          },
          { internalType: 'bool', name: 'isDefi', type: 'bool' },
        ],
        internalType: 'struct Collector.Response',
        name: 'r',
        type: 'tuple',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'a', type: 'address[]' },
      { internalType: 'address[]', name: 'b', type: 'address[]' },
    ],
    name: 'haveCommon',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
    name: 'isAdmin',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'x', type: 'address' },
      { internalType: 'address[]', name: 'a', type: 'address[]' },
    ],
    name: 'isOneOf',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
    name: 'isOperator',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'users', type: 'address[]' },
      { internalType: 'address[]', name: 'vaults', type: 'address[]' },
    ],
    name: 'multiCollect',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'address[]', name: 'underlyingTokens', type: 'address[]' },
          { internalType: 'uint256[]', name: 'underlyingAmounts', type: 'uint256[]' },
          { internalType: 'uint8[]', name: 'underlyingTokenDecimals', type: 'uint8[]' },
          { internalType: 'uint128[]', name: 'depositRatiosX96', type: 'uint128[]' },
          { internalType: 'uint128[]', name: 'withdrawalRatiosX96', type: 'uint128[]' },
          { internalType: 'uint256[]', name: 'pricesX96', type: 'uint256[]' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceETH', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceWSTETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceBaseTokenD18', type: 'uint256' },
          { internalType: 'bool', name: 'shouldCloseWithdrawalRequest', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
              { internalType: 'bytes32', name: 'tokensHash', type: 'bytes32' },
              { internalType: 'uint256[]', name: 'minAmounts', type: 'uint256[]' },
              { internalType: 'uint256', name: 'deadline', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct IVault.WithdrawalRequest',
            name: 'withdrawalRequest',
            type: 'tuple',
          },
          { internalType: 'bool', name: 'isDefi', type: 'bool' },
        ],
        internalType: 'struct Collector.Response[][]',
        name: 'responses',
        type: 'tuple[][]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeCollector',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'callerConfirmation', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
    name: 'requireAdmin',
    outputs: [],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
    name: 'requireAtLeastOperator',
    outputs: [],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'steth',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'weth',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'wethOracle',
    outputs: [{ internalType: 'contract IAggregatorV3', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'wsteth',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'wstethOracle',
    outputs: [{ internalType: 'contract IAggregatorV3', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const;
