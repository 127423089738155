import { PropsWithChildren, createContext, useEffect, useState } from 'react';

type ModalContextType = {
  activeModal: string;
  setActiveModal: (name: string) => void;
};

const initialValue = {
  activeModal: '',
  setActiveModal: () => null,
};

const ModalContext = createContext<ModalContextType>(initialValue);

const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeModal, setActiveModal] = useState('');

  useEffect(() => {
    const de = document.documentElement;
    let timeoutId: number;
    if (activeModal === '') {
      document.body.classList.add('stop-scrolling');
      timeoutId = window.setTimeout(() => {
        de.style.setProperty('--scrollBarWidth', '0');
        document.body.classList.remove('stop-scrolling');
      }, 250);
    } else {
      const scrollBarWidth = window.innerWidth - de.clientWidth;
      de.style.setProperty('--scrollBarWidth', `${scrollBarWidth}px`);
      document.body.classList.add('stop-scrolling');
    }
    return () => window.clearTimeout(timeoutId);
  }, [activeModal]);

  return (
    <ModalContext.Provider value={{ activeModal, setActiveModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
