import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';
import { Theme, useTheme } from '@/services/theme';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { APP_DOMAIN } from '@/core/config';

import { Button, SizeVariant } from '../Button/Button';
import { ReactComponent as P2p } from './icons/p2p.svg';
import { ReactComponent as Mev } from './icons/mev.svg';
import { ReactComponent as R7capital } from './icons/r7capital.svg';
import { ReactComponent as Steakhouse } from './icons/steakhouse.svg';
import { ReactComponent as Layers } from './icons/layers.svg';
import { ReactComponent as K3 } from './icons/k3.svg';
import { ReactComponent as Chorus } from './icons/chorus.svg';
import { ReactComponent as Infstones } from './icons/infstones.svg';
import { ReactComponent as Luganodes } from './icons/luganodes.svg';
import { ReactComponent as Renzo } from './icons/renzo.svg';
import { ReactComponent as Quasar } from './icons/quasar.svg';

const WelcomeStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 56px;
  overflow: hidden;
  max-width: 1232px;

  margin: 0 16px;
  @media (min-width: ${breakpoints.tabletSM}px) {
    margin: 0 auto;
  }

  .WelcomeBlock__infrastructure {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
    position: relative;
    overflow: hidden;

    padding-top: 198px;
    padding-bottom: 180px;
    @media (min-width: ${breakpoints.tabletSM}px) {
      padding-top: 173px;
      padding-bottom: 100px;
    }
  }

  .WelcomeBlock__ellipse {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    z-index: 0;

    &_blue {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #23164b 0%,
        #15105d 48.96%,
        #2d68c6 93.98%,
        #ffffff 100%
      );

      width: calc(80% + 110%);
      @media (min-width: ${breakpoints.tabletSM}px) {
        width: 80%;
      }
    }
    &_red {
      background: radial-gradient(50% 50% at 50% 50%, #f85f97 0%, #330e18 54.69%, #f00a38 100%);

      width: calc(94% + 135%);
      @media (min-width: ${breakpoints.tabletSM}px) {
        width: 94%;
      }
    }
    &_light-blue {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #0e122b 0%,
        #131d52 25.29%,
        #2750aa 60.8%,
        #a3eaff 92.71%,
        #ffffff 100%
      );

      width: calc(119% + 140%);
      @media (min-width: ${breakpoints.tabletSM}px) {
        width: 119%;
      }
    }
  }

  .WelcomeBlock__arrow {
    fill: ${({ theme }: { theme: Theme }) => theme.colors.gray16};
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    opacity: 0.5;

    width: 32px;
    height: 32px;
    padding: 8px;
    @media (min-width: ${breakpoints.tabletSM}px) {
      padding: 16px;
      width: 48px;
      height: 48px;
    }
  }

  .WelcomeBlock__liquid-restaking {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }: { theme: Theme }) => theme.colors.card};
    z-index: 1;
    position: relative;
    margin-bottom: 16px;
    border-bottom-left-radius: 56px;
    border-bottom-right-radius: 56px;
    font-family: Gramatika;

    padding: 50px 24px;
    flex-direction: column;
    @media (min-width: ${breakpoints.tabletSM}px) {
      padding: 151px 128px;
      flex-direction: row;
    }

    &-title {
      font-size: 24px;
      @media (min-width: ${breakpoints.tabletSM}px) {
        font-size: 36px;
      }
    }
    &-description {
      color: ${({ theme }: { theme: Theme }) => theme.colors.text.description};

      margin-bottom: 53px;
      margin-top: 23px;
      font-size: 20px;
      @media (min-width: ${breakpoints.tabletSM}px) {
        margin-bottom: 0;
        font-size: 24px;
        margin-top: 30px;
        margin-bottom: 60px;
      }
    }
    &-layers {
      max-width: 128px;
      max-height: 168px;
      margin-bottom: 60px;
      @media (min-width: ${breakpoints.tabletSM}px) {
        margin-bottom: 0;
        margin-right: 50px;
        min-height: 336px;
        min-width: 256px;
      }
    }

    &-wrapper {
      position: relative;
    }

    &-substrate {
      width: 100%;
      border-radius: 56px;
      height: 100px;
      position: absolute;
      left: 0;
      content: "''";
      z-index: 0;

      &:first-of-type {
        bottom: 4px;
        background: #62bfff;
      }
      &:nth-of-type(2) {
        bottom: 8px;
        background: #ea0b38;
      }
      &:nth-of-type(3) {
        bottom: 12px;
        background: #264ca4;
      }
    }
  }
`;

const TitleContainer = styled.div`
  z-index: 1;
`;

const trustedList = [
  P2p,
  Steakhouse,
  R7capital,
  Mev,
  Chorus,
  Renzo,
  Infstones,
  K3,
  Luganodes,
  Quasar,
];

const Title = styled.h1`
  margin: 0;
  font-weight: 300;
  line-height: 140%;
  font-family: Gramatika;
  text-align: center;

  font-size: 32px;
  @media (min-width: ${breakpoints.tabletSM}px) {
    font-size: 54px;
  }

  span {
    font-weight: 400;
  }
`;

const TrustedContainerStyled = styled.div`
  display: grid;
  align-items: center;
  gap: 20px;
  z-index: 1;
  font-size: 16px;
  opacity: 0.4;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${breakpoints.tabletSM}px) {
    display: flex;
    flex-wrap: wrap;
    max-width: 46%;
    justify-content: center;
  }

  svg {
    margin: 0 auto;

    &:last-of-type {
      grid-column: span 2;
    }
  }

  .WelcomeBlock__trusted-title {
    grid-column: span 2;
    @media (min-width: ${breakpoints.tabletSM}px) {
      width: 100%;
      flex-basis: 100%;
    }
    text-align: center;
  }
`;

const WelcomeBlock = () => {
  const { theme } = useTheme();

  return (
    <WelcomeStyled id="about" theme={theme}>
      <div className="WelcomeBlock__infrastructure">
        <div className="WelcomeBlock__ellipse WelcomeBlock__ellipse_light-blue" />
        <div className="WelcomeBlock__ellipse WelcomeBlock__ellipse_red" />
        <div className="WelcomeBlock__ellipse WelcomeBlock__ellipse_blue" />

        <TitleContainer>
          <Title theme={theme}>
            <span>Infrastructure for LRTs.</span>
            <br />
            Permissionless. Flexible. Modular.
          </Title>
        </TitleContainer>
        <Button variant={SizeVariant.SMALLER} type="link" href={`${APP_DOMAIN}/vaults`}>
          Explore
          <SpriteIcon className="WelcomeBlock__arrow" icon="arrow-left" />
        </Button>
        <TrustedContainerStyled>
          <span className="WelcomeBlock__trusted-title">Trusted by:</span>
          {trustedList.map((TrustedIcon, index) => (
            <TrustedIcon key={index} />
          ))}
        </TrustedContainerStyled>
      </div>

      <div className="WelcomeBlock__liquid-restaking-wrapper">
        <div className="WelcomeBlock__liquid-restaking-substrate" />
        <div className="WelcomeBlock__liquid-restaking-substrate" />
        <div className="WelcomeBlock__liquid-restaking-substrate" />

        <div className="WelcomeBlock__liquid-restaking">
          <Layers className="WelcomeBlock__liquid-restaking-layers" />
          <div>
            <div className="WelcomeBlock__liquid-restaking-title">
              Modular Liquid Restaking Primitive for Permissionless LRTs Creation & Curation
            </div>
            <div className="WelcomeBlock__liquid-restaking-description">
              Infrastructure for institutions and sophisticated risk curators for issuing and
              curating LRTs with diverse risk and yield profiles.
            </div>
          </div>
        </div>
      </div>
    </WelcomeStyled>
  );
};

export { WelcomeBlock };
