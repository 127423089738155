import { Defi, DefiCategory } from './types';

const defi: Defi[] = [
  {
    id: 'pool-pendle-mlw-pzeth-26-sept',
    pool: 'Mellow (Renzo) pzETH',
    protocol: 'Pendle',
    subtitle: 'Deposit until September 26th, 2024',
    address: '0xd3bb297264bd6115aE163db4153038a79D78acBA',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0xd3bb297264bd6115ae163db4153038a79d78acba?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: [
      '0xa2B4c479C98163BbDBd3987a9f3474bB7bc72F84', // YT
      '0xd3bb297264bd6115aE163db4153038a79D78acBA', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-rsena-26-sept',
    pool: 'Mellow rsENA',
    protocol: 'Pendle',
    subtitle: 'Deposit until September 26th, 2024',
    address: '0xf68300929DF14d933EBa2D45917563d5ed065666',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0xf68300929df14d933eba2d45917563d5ed065666?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0xc65433845ecD16688eda196497FA9130d6C47Bd8'],
    pointsAddresses: [
      '0x4F214344569653eb3F6b32a094df883FB9A80fB3', // YT
      '0xf68300929DF14d933EBa2D45917563d5ed065666', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-rsusde-26-sept',
    pool: 'Mellow rsUSDe',
    subtitle: 'Deposit until September 26th, 2024',
    protocol: 'Pendle',
    address: '0x4803a036c4352Bd53906B1C70040FA271D3aFE65',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x4803a036c4352bd53906b1c70040fa271d3afe65?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26'],
    pointsAddresses: [
      '0xDBdE8d8a18d76fc86E8d40C2f33ea4DDE161806e', // YT
      '0x4803a036c4352Bd53906B1C70040FA271D3aFE65', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-amphreth-25-july',
    pool: 'Mellow (MEV Capital) amphrETH',
    subtitle: 'Deposit until July 25th, 2024',
    protocol: 'Pendle',
    address: '0x3B621df9B429Ed1AD64428Ea7d8D142374c45121',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x3b621df9b429ed1ad64428ea7d8d142374c45121?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [
      '0x4dA430a0b5B22705ACFCC1bD5eE15603861ed98b', // YT
      '0x3B621df9B429Ed1AD64428Ea7d8D142374c45121', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-re7lrt-25-july',
    pool: 'Mellow (Re7Labs) Re7LRT',
    subtitle: 'Deposit until July 25th, 2024',
    protocol: 'Pendle',
    address: '0x2a41384C28B3E327bEDA4ffB3D3706CAd571d5fb',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x2a41384c28b3e327beda4ffb3d3706cad571d5fb?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: [
      '0x44188731D5bb43d0AED555d29f1013AEb13bD4a2', // YT
      '0x2a41384C28B3E327bEDA4ffB3D3706CAd571d5fb', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-steaklrt-25-july',
    pool: 'Mellow (Steakhouse Financial) steakLRT',
    subtitle: 'Deposit until July 25th, 2024',
    protocol: 'Pendle',
    address: '0x464F5A15Aca6Fe787Cf54fCF1E8AF6207939d297',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x464f5a15aca6fe787cf54fcf1e8af6207939d297?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: [
      '0xC48276dd601C83234D767F4d7d0c1B2B32BC7632', // YT
      '0x464F5A15Aca6Fe787Cf54fCF1E8AF6207939d297', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-rsteth-25-july',
    pool: 'Mellow (P2P.org) rstETH',
    subtitle: 'Deposit until July 25th, 2024',
    protocol: 'Pendle',
    address: '0xa96FeBd6c5FAF7739d3149bfB43211Ec6844a37C',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0xa96febd6c5faf7739d3149bfb43211ec6844a37c?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: [
      '0xF04f1F279C9dA038b917538D89947f0E00672487', // YT
      '0xa96FeBd6c5FAF7739d3149bfB43211Ec6844a37C', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-amphreth-26-sep',
    pool: 'Mellow (MEV Capital) amphrETH',
    subtitle: 'Deposit until September 26th, 2024',
    protocol: 'Pendle',
    address: '0x901e710C2F634131B36E3c68B072ED5DB9250f3E',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x901e710c2f634131b36e3c68b072ed5db9250f3e?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [
      '0x40c2889aEbD9E83AC7546D6932ef9E2EFb9Cc180', // YT
      '0x901e710C2F634131B36E3c68B072ED5DB9250f3E', // LP
    ],
  },
  {
    id: 'pool-pendle-amphor-amphrlrt-26-sep',
    pool: 'Amphor amphrLRT Pool',
    subtitle: 'Deposit until September 26th, 2024',
    protocol: 'Pendle',
    address: '0xb9e8bb1105382b018c6aDfd95Fd9272542Cc1776',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0xb9e8bb1105382b018c6adfd95fd9272542cc1776?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: [
      '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
      '0x5fD13359Ba15A84B76f7F87568309040176167cd',
      '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
      '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
      '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
    ],
    pointsAddresses: [
      '0x0aa0f6308214Bf53F6EAEFcD56a2bF4482EC4B34', // YT
      '0xb9e8bb1105382b018c6aDfd95Fd9272542Cc1776', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-re7lrt-26-sep',
    pool: 'Mellow (Re7 Labs) Re7LRT Pool',
    subtitle: 'Deposit until September 26th, 2024',
    protocol: 'Pendle',
    address: '0x020ABA13E46BaA0dDCBaB4505fABAd697e223908',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x020aba13e46baa0ddcbab4505fabad697e223908?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: [
      '0xFdfc9E828aE3708814996147D23aCeaeF4e4CdF2', // YT
      '0x020ABA13E46BaA0dDCBaB4505fABAd697e223908', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-rsteth-26-sep',
    pool: 'Mellow (P2P.org) rstETH',
    subtitle: 'Deposit until September 26th, 2024',
    protocol: 'Pendle',
    address: '0x9E27d0787B9554a1D734f60bD1365aDaF1aC298C',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x9e27d0787b9554a1d734f60bd1365adaf1ac298c?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: [
      '0x19aA5B6e7499c0eC15f695cb5A20CD6993185993', // YT
      '0x9E27d0787B9554a1D734f60bD1365aDaF1aC298C', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-steaklrt-26-sep',
    pool: 'Mellow (Steakhouse Financial) steakLRT',
    subtitle: 'Deposit until September 26th, 2024',
    protocol: 'Pendle',
    address: '0x3Fd77572FE52b37779FE88529956bf34777CbD2b',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x3fd77572fe52b37779fe88529956bf34777cbd2b?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: [
      '0x4084C7AF1eD7CEE6D7D9853403065D4F5b9816C8', // YT
      '0x3Fd77572FE52b37779FE88529956bf34777CbD2b', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-amphreth-26-dec',
    pool: 'Mellow (MEV Capital) amphrETH',
    subtitle: 'Deposit until December 26th, 2024',
    protocol: 'Pendle',
    address: '0x1C48cD1179Aa0C503A48fcD5852559942492E31b',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x1c48cd1179aa0c503a48fcd5852559942492e31b?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [
      '0x5dB8a2391a72F1114BbaE30eFc9CD89f4a29F988', // YT
      '0x1C48cD1179Aa0C503A48fcD5852559942492E31b', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-amphrlrt-26-dec',
    pool: 'amphrLRT',
    subtitle: 'Deposit until December 26th, 2024',
    protocol: 'Pendle',
    address: '0x7e0f3511044AFdaD9B4fd5C7Fa327cBeB90BEeBf',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x7e0f3511044afdad9b4fd5c7fa327cbeb90beebf?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: [
      '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
      '0x5fD13359Ba15A84B76f7F87568309040176167cd',
      '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
      '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
      '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
    ],
    pointsAddresses: [
      '0x1211a5b5f5a87B677074C391F0a80019aDF6bBd8', // YT
      '0x7e0f3511044AFdaD9B4fd5C7Fa327cBeB90BEeBf', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-re7lrt-26-dec',
    pool: 'Mellow (Re7Labs) Re7LRT',
    subtitle: 'Deposit until December 26th, 2024',
    protocol: 'Pendle',
    address: '0x1C5B54c0BD500A7430Ed34BD2413aF17725A7840',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x1c5b54c0bd500a7430ed34bd2413af17725a7840?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: [
      '0x89E7f4E5210A77Ac0f20511389Df71eC98ce9971', // YT
      '0x1C5B54c0BD500A7430Ed34BD2413aF17725A7840', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-rsteth-26-dec',
    pool: 'Mellow (P2P.org) rstETH',
    subtitle: 'Deposit until December 26th, 2024',
    protocol: 'Pendle',
    address: '0x409b499780Ac04dC4780F9B79fBEdE665Ac773d5',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x409b499780ac04dc4780f9b79fbede665ac773d5?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: [
      '0x11CCff2F748a0100dBd457FF7170A54e12064Aba', // YT
      '0x409b499780Ac04dC4780F9B79fBEdE665Ac773d5', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-rsusde-27-march-2025',
    pool: 'Mellow rsUSDe',
    subtitle: 'Deposit until March 27th, 2025',
    protocol: 'Pendle',
    address: '0xBCD9522EEf626dD0363347BDE6cAB105c2C7797e',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x890b6afc834c2a2cc6cb9b6627272ab4ecfd8271?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26'],
    pointsAddresses: [
      '0x079F21309eB9cbD2a387972eB2168d57C8542e32', // YT
      '0x890B6Afc834C2a2CC6cB9b6627272aB4EcFd8271', // LP
    ],
  },
  {
    id: 'pool-pendle-mlw-rsena-27-march-2025',
    pool: 'Mellow rsENA',
    subtitle: 'Deposit until March 27th, 2025',
    protocol: 'Pendle',
    address: '0x64046776c9C856fDc5c39c6EBa101adC8E18291f',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x7e0209ab6fa3c7730603b68799bbe9327dab7e88?chain=ethereum',
    boost: 2,
    category: 'yield-trading',
    vaults: ['0xc65433845ecD16688eda196497FA9130d6C47Bd8'],
    pointsAddresses: [
      '0x7d128742816aa09AF2e9F0Ca8C96450cEEC17d7a', // YT
      '0x7e0209ab6Fa3c7730603B68799BbE9327DAb7E88', // LP
    ],
  },
  {
    id: 'pool-amphrlrt',
    pool: 'amphrLRT',
    protocol: 'Amphor',
    address: '0x06824C27C8a0DbDe5F72f770eC82e3c0FD4DcEc3',
    network: 'ethereum',
    url: 'https://app.amphor.io/earn',
    boost: 1,
    category: 'yield',
    vaults: [
      '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
      '0x5fD13359Ba15A84B76f7F87568309040176167cd',
      '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
      '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
      '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
    ],
    pointsAddresses: ['0x06824C27C8a0DbDe5F72f770eC82e3c0FD4DcEc3'],
    showDefiTable: false,
  },
  {
    id: 'pool-curve-ezpz-eth',
    pool: 'ezpz/ETH',
    protocol: 'Curve',
    address: '0x8c65CeC3847ad99BdC02621bDBC89F2acE56934B',
    network: 'ethereum',
    url: 'https://curve.fi/#/ethereum/pools/factory-twocrypto-46',
    boost: 3,
    category: 'amm',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0x8c65CeC3847ad99BdC02621bDBC89F2acE56934B'],
  },
  {
    id: 'pool-curve-pzeth-wsteth',
    pool: 'pzETH/wstETH',
    protocol: 'Curve',
    address: '0xCec99Cff3632Cf1f16652cC5b1E80eB08bE25983',
    network: 'ethereum',
    url: 'https://curve.fi/#/ethereum/pools/factory-stable-ng-234',
    boost: 3,
    category: 'amm',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xCec99Cff3632Cf1f16652cC5b1E80eB08bE25983'],
  },
  {
    id: 'pool-uniswap-pzeth-wsteth',
    pool: 'pzETH/wstETH',
    protocol: 'Uniswap v3',
    address: '0xfC354F5cf57125a7D85E1165F4FcDfD3006Db61A',
    network: 'ethereum',
    url: 'https://app.uniswap.org/explore/pools/ethereum/0xfc354f5cf57125a7d85e1165f4fcdfd3006db61a',
    boost: 3,
    category: 'amm',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xfC354F5cf57125a7D85E1165F4FcDfD3006Db61A'],
  },
  {
    id: 'pool-uniswap-amphreth-wsteth',
    pool: 'amphrETH/wstETH',
    protocol: 'Uniswap v3',
    address: '0xf3AE577B0ce3F7CfBa28B5D07EcCEe54A959ef3C',
    network: 'ethereum',
    url: 'https://app.uniswap.org/explore/pools/ethereum/0xf3ae577b0ce3f7cfba28b5d07eccee54a959ef3c',
    boost: 3,
    category: 'amm',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0xf3AE577B0ce3F7CfBa28B5D07EcCEe54A959ef3C'],
  },
  {
    id: 'pool-uniswap-urlrt-wsteth',
    pool: 'urLRT/wstETH',
    protocol: 'Uniswap v3',
    address: '0x104b3E3ACD2396a7292223B5778Ea1caCdB68ec9',
    network: 'ethereum',
    url: 'https://app.uniswap.org/explore/pools/ethereum/0x104b3E3ACD2396a7292223B5778Ea1caCdB68ec9',
    boost: 3,
    category: 'amm',
    vaults: ['0x4f3Cc6359364004b245ad5bE36E6ad4e805dC961'],
    pointsAddresses: ['0x104b3E3ACD2396a7292223B5778Ea1caCdB68ec9'],
  },
  {
    id: 'pool-swell-wstETH',
    pool: 'amphrETH',
    protocol: 'Swell',
    address: '0x38D43a6Cb8DA0E855A42fB6b0733A0498531d774',
    network: 'ethereum',
    url: 'https://app.swellnetwork.io/swell-l2',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0x38D43a6Cb8DA0E855A42fB6b0733A0498531d774'],
  },
  {
    id: 'pool-fuel-l2-rsteth',
    pool: 'rstETH',
    protocol: 'Fuel.network',
    address: '0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67',
    network: 'ethereum',
    url: 'https://app.fuel.network/bridge?from=eth&to=fuel',
    boost: 1,
    category: 'l2',
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: ['0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67'],
  },
  {
    id: 'pool-fuel-l2-Re7LRT',
    pool: 'Re7LRT',
    protocol: 'Fuel.network',
    address: '0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67',
    network: 'ethereum',
    url: 'https://app.fuel.network/bridge?from=eth&to=fuel',
    boost: 1,
    category: 'l2',
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: ['0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67'],
  },
  {
    id: 'pool-fuel-l2-steakLRT',
    pool: 'steakLRT',
    protocol: 'Fuel.network',
    address: '0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67',
    network: 'ethereum',
    url: 'https://app.fuel.network/bridge?from=eth&to=fuel',
    boost: 1,
    category: 'l2',
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: ['0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67'],
  },
  {
    id: 'pool-fuel-l2-amphrETH',
    pool: 'amphrETH',
    protocol: 'Fuel.network',
    address: '0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67',
    network: 'ethereum',
    url: 'https://app.fuel.network/bridge?from=eth&to=fuel',
    boost: 1,
    category: 'l2',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67'],
  },
  {
    id: 'pool-fuel-l2-pzETH',
    pool: 'pzETH',
    protocol: 'Fuel.network',
    address: '0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67',
    network: 'ethereum',
    url: 'https://app.fuel.network/bridge?from=eth&to=fuel',
    boost: 1,
    category: 'l2',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67'],
  },
  {
    id: 'pool-fuel-l2-rsUSDE',
    pool: 'rsUSDE',
    protocol: 'Fuel.network',
    address: '0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67',
    network: 'ethereum',
    url: 'https://app.fuel.network/bridge?from=eth&to=fuel',
    boost: 1,
    category: 'l2',
    vaults: ['0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26'],
    pointsAddresses: ['0xa4cA04d02bfdC3A2DF56B9b6994520E69dF43F67'],
  },
  {
    id: 'pool-fuel-rsteth',
    pool: 'rstETH',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
  },
  {
    id: 'pool-fuel-Re7LRT',
    pool: 'Re7LRT',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
  },
  {
    id: 'pool-fuel-steakLRT',
    pool: 'steakLRT',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
  },
  {
    id: 'pool-fuel-amphrETH',
    pool: 'amphrETH',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
  },
  {
    id: 'pool-fuel-pzETH',
    pool: 'pzETH',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
  },
  {
    id: 'pool-fuel-rsUSDE',
    pool: 'rsUSDE',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
  },
  {
    id: 'pool-zircuit-rstETH',
    pool: 'rstETH',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
  },
  {
    id: 'pool-zircuit-re7lrt',
    pool: 'Re7LRT',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
  },
  {
    id: 'pool-zircuit-steaklrt',
    pool: 'steakLRT',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
  },
  {
    id: 'pool-zircuit-amphreth',
    pool: 'amphrETH',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
  },
  {
    id: 'pool-zircuit-pzeth',
    pool: 'pzETH',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    category: 'pre-deposit',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
  },
  {
    id: 'pool-5pool-amphreth-rsteth-re7lrt-steaklrt-wsteth',
    pool: '5pool',
    protocol: 'Balancer',
    address: '0xBA12222222228d8Ba445958a75a0704d566BF2C8',
    network: 'ethereum',
    url: 'https://app.balancer.fi/#/ethereum/pool/0x4216d5900a6109bba48418b5e2ab6cc4e61cf4770000000000000000000006a1',
    boost: 3,
    category: 'amm',
    vaults: [
      '0x5fD13359Ba15A84B76f7F87568309040176167cd',
      '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
      '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
      '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
      '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
    ],
    pointsAddresses: ['0xBA12222222228d8Ba445958a75a0704d566BF2C8'],
  },
  {
    id: 'pool-balancer-dvsteth-wsteth',
    pool: 'DVstETH/wstETH',
    protocol: 'Balancer',
    address: '0xBA12222222228d8Ba445958a75a0704d566BF2C8',
    network: 'ethereum',
    url: 'https://app.balancer.fi/#/ethereum/pool/0x632aafc2d3d2ca1764e702aa25c065c273cd08ed0000000000000000000006d6',
    boost: 2,
    category: 'amm',
    vaults: ['0x5E362eb2c0706Bd1d134689eC75176018385430B'],
    pointsAddresses: ['0xBA12222222228d8Ba445958a75a0704d566BF2C8'],
  },
  {
    id: 'pool-marginly-mlw-pt-amphreth-26-sep-2024',
    pool: 'PT Mellow amphrETH',
    subtitle: 'Deposit until September 26th, 2024',
    protocol: 'Marginly',
    address: '0xAc02A14F647029d2B2FF937032A2EDDfc6255A06',
    network: 'ethereum',
    url: 'https://app.marginly.com/mainnet/deposit/quote:0xAc02A14F647029d2B2FF937032A2EDDfc6255A06',
    boost: 2,
    category: 'leverage',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0xAc02A14F647029d2B2FF937032A2EDDfc6255A06'],
  },
  {
    id: 'pool-marginly-mlw-pt-pzeth-26-sep-2024',
    pool: 'PT Mellow pzETH',
    subtitle: 'Deposit until September 26th, 2024',
    protocol: 'Marginly',
    address: '0x51f95AAF94834b878eec18A812D840910370887f',
    network: 'ethereum',
    url: 'https://app.marginly.com/mainnet/deposit/quote:0x51f95AAF94834b878eec18A812D840910370887f',
    boost: 2,
    category: 'leverage',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0x51f95AAF94834b878eec18A812D840910370887f'],
  },
  {
    id: 'pool-gearbox-pzeth',
    pool: 'pzETH',
    protocol: 'Gearbox',
    address: '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0x8c9532a60e0e7c6bbd2b2c1303f63ace1c3e9811',
    boost: 2,
    category: 'leverage',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: [],
  },
  {
    id: 'pool-gearbox-rstETH',
    pool: 'rstETH',
    protocol: 'Gearbox',
    address: '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0x7a4effd87c2f3c55ca251080b1343b605f327e3a',
    boost: 2,
    category: 'leverage',
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: [],
  },
  {
    id: 'pool-gearbox-steakLRT',
    pool: 'steakLRT',
    protocol: 'Gearbox',
    address: '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0xbeef69ac7870777598a04b2bd4771c71212e6abc',
    boost: 2,
    category: 'leverage',
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: [],
  },
  {
    id: 'pool-gearbox-amphrETH',
    pool: 'amphrETH',
    protocol: 'Gearbox',
    address: '0x5fD13359Ba15A84B76f7F87568309040176167cd',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0x5fd13359ba15a84b76f7f87568309040176167cd',
    boost: 2,
    category: 'leverage',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [],
  },
  {
    id: 'pool-gearbox-Re7LRT',
    pool: 'Re7LRT',
    protocol: 'Gearbox',
    address: '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0x84631c0d0081fde56deb72f6de77abbbf6a9f93a',
    boost: 2,
    category: 'leverage',
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: [],
  },
  {
    id: 'pool-spectra-mlw-amphreth-20-sep',
    pool: 'Mellow (MEV Capital) amphrETH',
    subtitle: 'Deposit until September 20th, 2024',
    protocol: 'Spectra',
    address: '0x06c03069de266F58d10A0724a4f65C3D4feFfF4D',
    network: 'ethereum',
    url: 'https://app.spectra.finance/pools#mainnet/0x1c379572160a80975fa1dad8e491ff485611c8f6',
    boost: 2,
    category: 'yield',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [
      '0x380e5d1A776A80A11291633Ac77786E7061c40e0', // YT
      '0x4d46B713fc1e38F9923Cd2A5ca5050574F9f0d73', // LP
    ],
  },
  {
    id: 'pool-spectra-amphrlrt-6-oct',
    pool: 'amphrLRT',
    subtitle: 'Deposit until October 6th, 2024',
    protocol: 'Spectra',
    address: '0xeaB93Ec2A82eB235a1f426Accd9254f2aB32A99d',
    network: 'ethereum',
    url: 'https://app.spectra.finance/pools#mainnet/0xdce8dfa05f9af2c16793f6b8e77597b7b7bf0c50',
    boost: 2,
    category: 'yield',
    vaults: [
      '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
      '0x5fD13359Ba15A84B76f7F87568309040176167cd',
      '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
      '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
      '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
    ],
    pointsAddresses: [
      '0xA52E41ddF4461f93840c9E26462fFdC333e015B9', // YT
      '0x9b1E5E8909609F5b2a07394746f28e1eB0B236Ae', // LP
    ],
  },
  {
    id: 'pool-spectra-amphrlrt-25-march-2025',
    pool: 'amphrLRT',
    subtitle: 'Deposit until March 25th, 2025',
    protocol: 'Spectra',
    address: '0xeaB93Ec2A82eB235a1f426Accd9254f2aB32A99d',
    network: 'ethereum',
    url: 'https://app.spectra.finance/pools#mainnet/0x588eab5777e51ece898bb71976715072e6f7843f',
    boost: 2,
    category: 'yield',
    vaults: [
      '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
      '0x5fD13359Ba15A84B76f7F87568309040176167cd',
      '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
      '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
      '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
    ],
    pointsAddresses: [
      '0x8e82B4295996fEDA8648B9cE3fF6d0ec40c4f83B', // YT
      '0x6228bBF6EbC60303c822d62ED06C12ed43EdEB58', // LP
    ],
  },
  {
    id: 'pool-silo-amphreth',
    pool: 'amphrETH',
    protocol: 'Silo',
    address: '0x992298cb9a6aafaDBc038dC85f1a1C012F45b67d',
    network: 'ethereum',
    url: 'https://app.silo.finance/silo/0x992298cb9a6aafaDBc038dC85f1a1C012F45b67d',
    boost: 2,
    category: 'lending',
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0x992298cb9a6aafaDBc038dC85f1a1C012F45b67d'],
  },
  {
    id: 'pool-silo-re7lrt',
    pool: 'Re7LRT',
    protocol: 'Silo',
    address: '0x884725A8dd9E8591E851C88Eb665735f8D86da8C',
    network: 'ethereum',
    url: 'https://app.silo.finance/silo/0x884725A8dd9E8591E851C88Eb665735f8D86da8C',
    boost: 2,
    category: 'lending',
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: ['0x884725A8dd9E8591E851C88Eb665735f8D86da8C'],
  },
  {
    id: 'pool-silo-pzeth',
    pool: 'pzETH',
    protocol: 'Silo',
    address: '0x1cdbde50F9D122CCE8fe8D08d0D482b0161fFD15',
    network: 'ethereum',
    url: 'https://app.silo.finance/silo/0x1cdbde50F9D122CCE8fe8D08d0D482b0161fFD15',
    boost: 2,
    category: 'lending',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0x1cdbde50F9D122CCE8fe8D08d0D482b0161fFD15'],
  },
  {
    id: 'pool-silo-rsteth',
    pool: 'rstETH',
    protocol: 'Silo',
    address: '0xb3499737F35973a73A6270ae6616eca3E2dC0f65',
    network: 'ethereum',
    url: 'https://app.silo.finance/silo/0xb3499737F35973a73A6270ae6616eca3E2dC0f65',
    boost: 2,
    category: 'lending',
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: ['0xb3499737F35973a73A6270ae6616eca3E2dC0f65'],
  },
  {
    id: 'pool-zerolend-pzeth',
    pool: 'pzETH',
    protocol: 'Zerolend',
    address: '0xd9855847FFD9Bc0c5f3efFbEf67B558dBf090a71',
    network: 'ethereum',
    url: 'https://app.zerolend.xyz/reserve-overview/?underlyingAsset=0x8c9532a60e0e7c6bbd2b2c1303f63ace1c3e9811&marketName=proto_mainnet_lrt_v3',
    boost: 2,
    category: 'lending',
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xd9855847FFD9Bc0c5f3efFbEf67B558dBf090a71'],
  },
];

export function getCategoryName(category: DefiCategory) {
  switch (category) {
    case 'lending':
      return 'Lending';
    case 'leverage':
      return 'Leverage';
    case 'amm':
      return 'AMM';
    case 'yield':
      return 'Yield';
    case 'yield-trading':
      return 'Yield trading';
    case 'pre-deposit':
      return 'Pre-deposit';
    case 'l2':
      return 'L2';
    default:
      return '';
  }
}

export default defi;
