export const GA_ID = 'G-T7WVBCB1LV';

export interface GACookiesConsent {
  marketing?: boolean;
  analytical?: boolean;
}

export const grantGAConsent = ({ marketing, analytical }: GACookiesConsent) => {
  const consent: { [key: string]: string } = {};

  if (marketing != null) {
    consent.ad_storage = marketing ? 'granted' : 'denied';
  }

  if (analytical != null) {
    consent.analytics_storage = analytical ? 'granted' : 'denied';
  }

  window.dataLayer.push(['consent', 'update', consent]);
};
