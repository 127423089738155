import { useState, useEffect, useCallback } from 'react';

export const useBreakpointDown = (breakpoint: number) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowChange = useCallback(() => {
    if (Math.max(window.innerWidth, document.documentElement.clientWidth) <= breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [breakpoint]);

  useEffect(() => {
    handleWindowChange();
    window.addEventListener(`resize`, handleWindowChange, false);

    return () => {
      window.removeEventListener(`resize`, handleWindowChange, false);
    };
  }, [handleWindowChange]);

  return isMobile;
};
