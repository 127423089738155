import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';
import { Theme } from '@/shared/themes';
import { useTheme } from '@/services/theme';

import { CardLink } from '../../components/CardLink';
import { PageSection } from '../../components/PageSection';
import { ReactComponent as LinkIcon } from './linkIcon.svg';

type Props = unknown;

const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 6px;
  @media (min-width: ${breakpoints.tabletLG}px) {
    gap: 8px;
    flex-direction: row;
  }
`;

const StyledCard = styled.a`
  font-family: Gramatika;
  display: flex;
  border: 1px solid #1f2028;
  background: #18191f;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  padding: 24px;
  font-size: 16px;
  gap: 24px;
  width: 100%;
  margin-top: 30px;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  text-decoration: none;
  @media (min-width: ${breakpoints.tabletMD}px) {
    margin-bottom: 24px;
  }
`;

const Documentation: React.FC<Props> = () => {
  const { theme } = useTheme();

  return (
    <PageSection title="Documentation" id="documentation">
      <StyledLinks>
        <CardLink
          title="Getting Started"
          text="Modular LRT primitive overview."
          background="linear-gradient(99.19deg, #EC0B38 0%, #860620 100%);"
          url="https://docs.mellow.finance/mellow-lrt-primitive/overview"
        />
        <CardLink
          title="Core"
          text="Protocol Smart Contracts details."
          background="linear-gradient(260.81deg, #2855AF 0%, #112349 100%);"
          url="https://docs.mellow.finance/mellow-lrt-primitive/lrt-contracts"
        />
        <CardLink
          title="User Guide"
          text="Deposit and Withdrawal instructions."
          background="linear-gradient(79.5deg, #4E505B 5.36%, #5E616E 94.64%);"
          url="https://docs.mellow.finance/mellow-lrt-primitive/user-tutorials"
        />
      </StyledLinks>

      <StyledCard theme={theme} href="https://forms.gle/4AtqYVMHXeYr21wX7" target="_blank">
        <span>
          <strong>LRT Curation: </strong> Fill a form for curators to initiate onboarding process
        </span>
        <span style={{ width: 24, display: 'flex', alignItems: 'center' }}>
          <LinkIcon />
        </span>
      </StyledCard>
    </PageSection>
  );
};

export { Documentation };
