import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';
import { PageContainer } from '@/shared/components/PageContainer';
import { APP_DOMAIN } from '@/core/config';

import { Resources } from './components/Resources';
import { resourcesList } from './fixtures';
import { Typography } from '../Typography';
import bg from './bg.svg';

const StyledFooter = styled.footer`
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  font-size: 1.4rem;
  margin-top: auto;
  position: relative;

  .Footer__substrate {
    width: 100%;
    border-radius: 56px;
    height: 100px;
    position: absolute;
    left: 0;
    content: "''";
    z-index: 0;

    &:first-of-type {
      top: -12px;
      background: #62bfff;
    }
    &:nth-of-type(2) {
      top: -8px;
      background: #ea0b38;
    }
    &:nth-of-type(3) {
      top: -4px;
      background: #264ca4;
    }
  }

  .Footer__app-link {
    text-decoration: none;
    padding: 8px 10px;
    border-radius: 50px;
    background: #ff4894;
    color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
    font-family: Gramatika;
    font-size: 12px;
  }

  .Footer__last-p {
    margin-bottom: 30px;
  }
`;

const StyledPageContainer = styled(PageContainer)`
  background: url(${bg});
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background.footer};
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 56px;
  border-top-right-radius: 56px;
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: unset;

  padding: 52px 30px 28px;
  @media (min-width: ${breakpoints.mobileXL}px) {
    padding: 92px 30px 80px;
  }
`;

const StyledContent = styled.div`
  display: grid;
  grid-template: repeat(4, auto) / 100%;
  max-width: 1136px;

  gap: 28px 32px;
  @media (min-width: ${breakpoints.mobileXL}px) {
    grid-template: auto auto / repeat(3, 1fr);
    column-gap: 64px;
  }
  @media (min-width: ${breakpoints.tabletXL}px) {
    gap: 56px 32px;
    grid-template: 1fr / repeat(5, 1fr);
  }
`;

const StyledInfo = styled.div`
  grid-area: 1/1/2/2;
  @media (min-width: ${breakpoints.tabletXL}px) {
    grid-area: 1/1/2/3;
  }
`;

const Footer = () => {
  const { theme } = useTheme();

  return (
    <StyledFooter theme={theme}>
      <div className="Footer__substrate" />
      <div className="Footer__substrate" />
      <div className="Footer__substrate" />

      <StyledPageContainer theme={theme}>
        <StyledContent>
          <StyledInfo>
            <Typography variant="h3" size={1.4} mb={2.4}>
              Mellow Protocol
            </Typography>
            <Typography color="primary" variant="body2" mb={1.6}>
              Modular Liquid Restaking Primitive for Permissionless LRTs Creation & Curation.
            </Typography>

            <a
              className="Footer__app-link"
              target="_blank"
              href={`${APP_DOMAIN}/products`}
              rel="noreferrer"
            >
              Go to Mellow V1
            </a>
          </StyledInfo>

          {resourcesList.map(item => (
            <Resources key={item.title} {...item} />
          ))}
        </StyledContent>
      </StyledPageContainer>
    </StyledFooter>
  );
};

export { Footer };
