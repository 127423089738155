export const vaultAbi = [
  {
    type: 'constructor',
    inputs: [
      { name: 'name_', type: 'string', internalType: 'string' },
      { name: 'symbol_', type: 'string', internalType: 'string' },
      { name: 'admin', type: 'address', internalType: 'address' },
    ],
    stateMutability: 'nonpayable',
  },
  { type: 'receive', stateMutability: 'payable' },
  {
    type: 'function',
    name: 'ADMIN_DELEGATE_ROLE',
    inputs: [],
    outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'ADMIN_ROLE',
    inputs: [],
    outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'D9',
    inputs: [],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'DEFAULT_ADMIN_ROLE',
    inputs: [],
    outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'OPERATOR',
    inputs: [],
    outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'Q96',
    inputs: [],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'addToken',
    inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'addTvlModule',
    inputs: [{ name: 'module', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'allowance',
    inputs: [
      { name: 'owner', type: 'address', internalType: 'address' },
      { name: 'spender', type: 'address', internalType: 'address' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'analyzeRequest',
    inputs: [
      {
        name: 's',
        type: 'tuple',
        internalType: 'struct IVault.ProcessWithdrawalsStack',
        components: [
          { name: 'tokens', type: 'address[]', internalType: 'address[]' },
          { name: 'ratiosX96', type: 'uint128[]', internalType: 'uint128[]' },
          { name: 'erc20Balances', type: 'uint256[]', internalType: 'uint256[]' },
          { name: 'totalSupply', type: 'uint256', internalType: 'uint256' },
          { name: 'totalValue', type: 'uint256', internalType: 'uint256' },
          { name: 'ratiosX96Value', type: 'uint256', internalType: 'uint256' },
          { name: 'timestamp', type: 'uint256', internalType: 'uint256' },
          { name: 'feeD9', type: 'uint256', internalType: 'uint256' },
          { name: 'tokensHash', type: 'bytes32', internalType: 'bytes32' },
        ],
      },
      {
        name: 'request',
        type: 'tuple',
        internalType: 'struct IVault.WithdrawalRequest',
        components: [
          { name: 'to', type: 'address', internalType: 'address' },
          { name: 'lpAmount', type: 'uint256', internalType: 'uint256' },
          { name: 'tokensHash', type: 'bytes32', internalType: 'bytes32' },
          { name: 'minAmounts', type: 'uint256[]', internalType: 'uint256[]' },
          { name: 'deadline', type: 'uint256', internalType: 'uint256' },
          { name: 'timestamp', type: 'uint256', internalType: 'uint256' },
        ],
      },
    ],
    outputs: [
      { name: '', type: 'bool', internalType: 'bool' },
      { name: '', type: 'bool', internalType: 'bool' },
      { name: 'expectedAmounts', type: 'uint256[]', internalType: 'uint256[]' },
    ],
    stateMutability: 'pure',
  },
  {
    type: 'function',
    name: 'approve',
    inputs: [
      { name: 'spender', type: 'address', internalType: 'address' },
      { name: 'value', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'balanceOf',
    inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'baseTvl',
    inputs: [],
    outputs: [
      { name: 'tokens', type: 'address[]', internalType: 'address[]' },
      { name: 'amounts', type: 'uint256[]', internalType: 'uint256[]' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'calculateStack',
    inputs: [],
    outputs: [
      {
        name: 's',
        type: 'tuple',
        internalType: 'struct IVault.ProcessWithdrawalsStack',
        components: [
          { name: 'tokens', type: 'address[]', internalType: 'address[]' },
          { name: 'ratiosX96', type: 'uint128[]', internalType: 'uint128[]' },
          { name: 'erc20Balances', type: 'uint256[]', internalType: 'uint256[]' },
          { name: 'totalSupply', type: 'uint256', internalType: 'uint256' },
          { name: 'totalValue', type: 'uint256', internalType: 'uint256' },
          { name: 'ratiosX96Value', type: 'uint256', internalType: 'uint256' },
          { name: 'timestamp', type: 'uint256', internalType: 'uint256' },
          { name: 'feeD9', type: 'uint256', internalType: 'uint256' },
          { name: 'tokensHash', type: 'bytes32', internalType: 'bytes32' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'cancelWithdrawalRequest',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'configurator',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'contract IVaultConfigurator' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'decimals',
    inputs: [],
    outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'delegateCall',
    inputs: [
      { name: 'to', type: 'address', internalType: 'address' },
      { name: 'data', type: 'bytes', internalType: 'bytes' },
    ],
    outputs: [
      { name: 'success', type: 'bool', internalType: 'bool' },
      { name: 'response', type: 'bytes', internalType: 'bytes' },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'deposit',
    inputs: [
      { name: 'to', type: 'address', internalType: 'address' },
      { name: 'amounts', type: 'uint256[]', internalType: 'uint256[]' },
      { name: 'minLpAmount', type: 'uint256', internalType: 'uint256' },
      { name: 'deadline', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [
      { name: 'actualAmounts', type: 'uint256[]', internalType: 'uint256[]' },
      { name: 'lpAmount', type: 'uint256', internalType: 'uint256' },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'emergencyWithdraw',
    inputs: [
      { name: 'minAmounts', type: 'uint256[]', internalType: 'uint256[]' },
      { name: 'deadline', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: 'actualAmounts', type: 'uint256[]', internalType: 'uint256[]' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'externalCall',
    inputs: [
      { name: 'to', type: 'address', internalType: 'address' },
      { name: 'data', type: 'bytes', internalType: 'bytes' },
    ],
    outputs: [
      { name: 'success', type: 'bool', internalType: 'bool' },
      { name: 'response', type: 'bytes', internalType: 'bytes' },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'getRoleAdmin',
    inputs: [{ name: 'role', type: 'bytes32', internalType: 'bytes32' }],
    outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getRoleMember',
    inputs: [
      { name: 'role', type: 'bytes32', internalType: 'bytes32' },
      { name: 'index', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getRoleMemberCount',
    inputs: [{ name: 'role', type: 'bytes32', internalType: 'bytes32' }],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'grantRole',
    inputs: [
      { name: 'role', type: 'bytes32', internalType: 'bytes32' },
      { name: 'account', type: 'address', internalType: 'address' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'hasRole',
    inputs: [
      { name: 'role', type: 'bytes32', internalType: 'bytes32' },
      { name: 'account', type: 'address', internalType: 'address' },
    ],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'isAdmin',
    inputs: [{ name: 'sender', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'isOperator',
    inputs: [{ name: 'sender', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'name',
    inputs: [],
    outputs: [{ name: '', type: 'string', internalType: 'string' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pendingWithdrawers',
    inputs: [
      { name: 'limit', type: 'uint256', internalType: 'uint256' },
      { name: 'offset', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: 'result', type: 'address[]', internalType: 'address[]' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pendingWithdrawers',
    inputs: [],
    outputs: [{ name: '', type: 'address[]', internalType: 'address[]' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pendingWithdrawersCount',
    inputs: [],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'processWithdrawals',
    inputs: [{ name: 'users', type: 'address[]', internalType: 'address[]' }],
    outputs: [{ name: 'statuses', type: 'bool[]', internalType: 'bool[]' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'registerWithdrawal',
    inputs: [
      { name: 'to', type: 'address', internalType: 'address' },
      { name: 'lpAmount', type: 'uint256', internalType: 'uint256' },
      { name: 'minAmounts', type: 'uint256[]', internalType: 'uint256[]' },
      { name: 'deadline', type: 'uint256', internalType: 'uint256' },
      { name: 'requestDeadline', type: 'uint256', internalType: 'uint256' },
      { name: 'closePrevious', type: 'bool', internalType: 'bool' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'removeToken',
    inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'removeTvlModule',
    inputs: [{ name: 'module', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'renounceRole',
    inputs: [
      { name: 'role', type: 'bytes32', internalType: 'bytes32' },
      { name: 'callerConfirmation', type: 'address', internalType: 'address' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'requireAdmin',
    inputs: [{ name: 'sender', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'requireAtLeastOperator',
    inputs: [{ name: 'sender', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'revokeRole',
    inputs: [
      { name: 'role', type: 'bytes32', internalType: 'bytes32' },
      { name: 'account', type: 'address', internalType: 'address' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'supportsInterface',
    inputs: [{ name: 'interfaceId', type: 'bytes4', internalType: 'bytes4' }],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'symbol',
    inputs: [],
    outputs: [{ name: '', type: 'string', internalType: 'string' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'totalSupply',
    inputs: [],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'transfer',
    inputs: [
      { name: 'to', type: 'address', internalType: 'address' },
      { name: 'value', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'transferFrom',
    inputs: [
      { name: 'from', type: 'address', internalType: 'address' },
      { name: 'to', type: 'address', internalType: 'address' },
      { name: 'value', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'tvlModules',
    inputs: [],
    outputs: [{ name: '', type: 'address[]', internalType: 'address[]' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'underlyingTokens',
    inputs: [],
    outputs: [{ name: '', type: 'address[]', internalType: 'address[]' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'underlyingTvl',
    inputs: [],
    outputs: [
      { name: 'tokens', type: 'address[]', internalType: 'address[]' },
      { name: 'amounts', type: 'uint256[]', internalType: 'uint256[]' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'withdrawalRequest',
    inputs: [{ name: 'user', type: 'address', internalType: 'address' }],
    outputs: [
      {
        name: '',
        type: 'tuple',
        internalType: 'struct IVault.WithdrawalRequest',
        components: [
          { name: 'to', type: 'address', internalType: 'address' },
          { name: 'lpAmount', type: 'uint256', internalType: 'uint256' },
          { name: 'tokensHash', type: 'bytes32', internalType: 'bytes32' },
          { name: 'minAmounts', type: 'uint256[]', internalType: 'uint256[]' },
          { name: 'deadline', type: 'uint256', internalType: 'uint256' },
          { name: 'timestamp', type: 'uint256', internalType: 'uint256' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'event',
    name: 'Approval',
    inputs: [
      { name: 'owner', type: 'address', indexed: true, internalType: 'address' },
      { name: 'spender', type: 'address', indexed: true, internalType: 'address' },
      { name: 'value', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'DelegateCall',
    inputs: [
      { name: 'to', type: 'address', indexed: true, internalType: 'address' },
      { name: 'data', type: 'bytes', indexed: false, internalType: 'bytes' },
      { name: 'success', type: 'bool', indexed: false, internalType: 'bool' },
      { name: 'response', type: 'bytes', indexed: false, internalType: 'bytes' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Deposit',
    inputs: [
      { name: 'to', type: 'address', indexed: true, internalType: 'address' },
      { name: 'amounts', type: 'uint256[]', indexed: false, internalType: 'uint256[]' },
      { name: 'lpAmount', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'DepositCallback',
    inputs: [
      { name: 'callback', type: 'address', indexed: true, internalType: 'address' },
      { name: 'amounts', type: 'uint256[]', indexed: false, internalType: 'uint256[]' },
      { name: 'lpAmount', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'EmergencyWithdrawal',
    inputs: [
      { name: 'from', type: 'address', indexed: true, internalType: 'address' },
      {
        name: 'request',
        type: 'tuple',
        indexed: false,
        internalType: 'struct IVault.WithdrawalRequest',
        components: [
          { name: 'to', type: 'address', internalType: 'address' },
          { name: 'lpAmount', type: 'uint256', internalType: 'uint256' },
          { name: 'tokensHash', type: 'bytes32', internalType: 'bytes32' },
          { name: 'minAmounts', type: 'uint256[]', internalType: 'uint256[]' },
          { name: 'deadline', type: 'uint256', internalType: 'uint256' },
          { name: 'timestamp', type: 'uint256', internalType: 'uint256' },
        ],
      },
      { name: 'amounts', type: 'uint256[]', indexed: false, internalType: 'uint256[]' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ExternalCall',
    inputs: [
      { name: 'to', type: 'address', indexed: true, internalType: 'address' },
      { name: 'data', type: 'bytes', indexed: false, internalType: 'bytes' },
      { name: 'success', type: 'bool', indexed: false, internalType: 'bool' },
      { name: 'response', type: 'bytes', indexed: false, internalType: 'bytes' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RoleAdminChanged',
    inputs: [
      { name: 'role', type: 'bytes32', indexed: true, internalType: 'bytes32' },
      { name: 'previousAdminRole', type: 'bytes32', indexed: true, internalType: 'bytes32' },
      { name: 'newAdminRole', type: 'bytes32', indexed: true, internalType: 'bytes32' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RoleGranted',
    inputs: [
      { name: 'role', type: 'bytes32', indexed: true, internalType: 'bytes32' },
      { name: 'account', type: 'address', indexed: true, internalType: 'address' },
      { name: 'sender', type: 'address', indexed: true, internalType: 'address' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RoleRevoked',
    inputs: [
      { name: 'role', type: 'bytes32', indexed: true, internalType: 'bytes32' },
      { name: 'account', type: 'address', indexed: true, internalType: 'address' },
      { name: 'sender', type: 'address', indexed: true, internalType: 'address' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TokenAdded',
    inputs: [{ name: 'token', type: 'address', indexed: false, internalType: 'address' }],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TokenRemoved',
    inputs: [{ name: 'token', type: 'address', indexed: false, internalType: 'address' }],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Transfer',
    inputs: [
      { name: 'from', type: 'address', indexed: true, internalType: 'address' },
      { name: 'to', type: 'address', indexed: true, internalType: 'address' },
      { name: 'value', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TvlModuleAdded',
    inputs: [{ name: 'module', type: 'address', indexed: false, internalType: 'address' }],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TvlModuleRemoved',
    inputs: [{ name: 'module', type: 'address', indexed: false, internalType: 'address' }],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'WithdrawCallback',
    inputs: [{ name: 'callback', type: 'address', indexed: true, internalType: 'address' }],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'WithdrawalRequestCanceled',
    inputs: [
      { name: 'user', type: 'address', indexed: true, internalType: 'address' },
      { name: 'origin', type: 'address', indexed: false, internalType: 'address' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'WithdrawalRequested',
    inputs: [
      { name: 'from', type: 'address', indexed: true, internalType: 'address' },
      {
        name: 'request',
        type: 'tuple',
        indexed: false,
        internalType: 'struct IVault.WithdrawalRequest',
        components: [
          { name: 'to', type: 'address', internalType: 'address' },
          { name: 'lpAmount', type: 'uint256', internalType: 'uint256' },
          { name: 'tokensHash', type: 'bytes32', internalType: 'bytes32' },
          { name: 'minAmounts', type: 'uint256[]', internalType: 'uint256[]' },
          { name: 'deadline', type: 'uint256', internalType: 'uint256' },
          { name: 'timestamp', type: 'uint256', internalType: 'uint256' },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'WithdrawalsProcessed',
    inputs: [
      { name: 'users', type: 'address[]', indexed: false, internalType: 'address[]' },
      { name: 'statuses', type: 'bool[]', indexed: false, internalType: 'bool[]' },
    ],
    anonymous: false,
  },
  { type: 'error', name: 'AccessControlBadConfirmation', inputs: [] },
  {
    type: 'error',
    name: 'AccessControlUnauthorizedAccount',
    inputs: [
      { name: 'account', type: 'address', internalType: 'address' },
      { name: 'neededRole', type: 'bytes32', internalType: 'bytes32' },
    ],
  },
  {
    type: 'error',
    name: 'AddressEmptyCode',
    inputs: [{ name: 'target', type: 'address', internalType: 'address' }],
  },
  {
    type: 'error',
    name: 'AddressInsufficientBalance',
    inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
  },
  { type: 'error', name: 'AddressZero', inputs: [] },
  { type: 'error', name: 'Deadline', inputs: [] },
  {
    type: 'error',
    name: 'ERC20InsufficientAllowance',
    inputs: [
      { name: 'spender', type: 'address', internalType: 'address' },
      { name: 'allowance', type: 'uint256', internalType: 'uint256' },
      { name: 'needed', type: 'uint256', internalType: 'uint256' },
    ],
  },
  {
    type: 'error',
    name: 'ERC20InsufficientBalance',
    inputs: [
      { name: 'sender', type: 'address', internalType: 'address' },
      { name: 'balance', type: 'uint256', internalType: 'uint256' },
      { name: 'needed', type: 'uint256', internalType: 'uint256' },
    ],
  },
  {
    type: 'error',
    name: 'ERC20InvalidApprover',
    inputs: [{ name: 'approver', type: 'address', internalType: 'address' }],
  },
  {
    type: 'error',
    name: 'ERC20InvalidReceiver',
    inputs: [{ name: 'receiver', type: 'address', internalType: 'address' }],
  },
  {
    type: 'error',
    name: 'ERC20InvalidSender',
    inputs: [{ name: 'sender', type: 'address', internalType: 'address' }],
  },
  {
    type: 'error',
    name: 'ERC20InvalidSpender',
    inputs: [{ name: 'spender', type: 'address', internalType: 'address' }],
  },
  { type: 'error', name: 'FailedInnerCall', inputs: [] },
  { type: 'error', name: 'Forbidden', inputs: [] },
  { type: 'error', name: 'InsufficientAmount', inputs: [] },
  { type: 'error', name: 'InsufficientLpAmount', inputs: [] },
  { type: 'error', name: 'InvalidLength', inputs: [] },
  { type: 'error', name: 'InvalidState', inputs: [] },
  { type: 'error', name: 'InvalidToken', inputs: [] },
  { type: 'error', name: 'LimitOverflow', inputs: [] },
  { type: 'error', name: 'NonZeroValue', inputs: [] },
  { type: 'error', name: 'ReentrancyGuardReentrantCall', inputs: [] },
  {
    type: 'error',
    name: 'SafeERC20FailedOperation',
    inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
  },
  { type: 'error', name: 'ValueZero', inputs: [] },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
      { internalType: 'uint256', name: 'minLpAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
      { internalType: 'uint256', name: 'referralCode', type: 'uint256' },
    ],
    name: 'deposit',
    outputs: [
      { internalType: 'uint256[]', name: 'actualAmounts', type: 'uint256[]' },
      { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;
