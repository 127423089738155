import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

type Props = {
  maxWidth?: number;
  isTop?: boolean;
  children: React.ReactNode;
};

const StyledContainer = styled.div`
  position: relative;
  margin: 0 auto;
  min-width: 320px;
  max-width: ${(props: { $maxWidth: number }) => (props.$maxWidth ? props.$maxWidth : 1136)}px;

  @media (max-width: ${breakpoints.laptopSM}px) {
    width: 80%;
  }

  @media (max-width: ${breakpoints.laptopSM}px) {
    padding: 0 16px;
    margin: 0;
    width: auto;
  }

  @media (max-width: ${breakpoints.mobileMD}px) {
    margin-top: ${(props: { $isTop: boolean }) => (props.$isTop ? '64px' : '0px')};
  }
`;

const LandingContainer: React.FC<Props> = ({ children, isTop, maxWidth }) => (
  <StyledContainer $maxWidth={maxWidth} $isTop={isTop}>
    {children}
  </StyledContainer>
);
export { LandingContainer };
