import styled, { keyframes } from 'styled-components';

import { useTheme } from '@/services/theme';

type Props = {
  className?: string;
  color?: string;
  size?: string;
  thickness?: string;
  style?: React.CSSProperties;
};

const rotate = keyframes`
  100% { transform: rotate(360deg); }
`;

const StyledSpinner = styled.span`
  display: inline-block;
  width: ${({ size }: { size: string }) => size};
  height: ${({ size }: { size: string }) => size};
  border: ${({ color, thickness }: { color: string; thickness: string }) =>
    `${thickness} solid ${color}`};
  border-radius: ${({ size }: { size: string }) => `${size}`};
  -webkit-mask-clip: border;
  -webkit-mask-image: conic-gradient(
    from 90deg at 50% 50%,
    rgba(255, 255, 255, 0) 0deg,
    rgba(255, 255, 255, 0) 90deg,
    #fff 360deg
  );
  animation: ${rotate} 1.5s linear infinite;
`;

const Spinner: React.FC<Props> = ({
  className,
  color,
  size = '2.4rem',
  thickness = '0.4rem',
  ...props
}) => {
  const { theme } = useTheme();
  const spinnerColor = color || theme.colors.text.primary;

  return (
    <StyledSpinner
      className={className}
      color={spinnerColor}
      size={size}
      thickness={thickness}
      {...props}
    />
  );
};

export { Spinner };
