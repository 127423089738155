import styled from 'styled-components';
import cn from 'classnames';

import { Theme, useTheme } from '@/services/theme';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { breakpoints } from '@/shared/styles';
import { useBreakpointDown } from '@/shared/utils';

import { PageSection } from '../../components/PageSection';

type Props = unknown;

const StyledContent = styled.div`
  font-family: Gramatika;

  .SupportedTokens {
    &__description {
      color: ${({ theme }: { theme: Theme }) => theme.colors.text.description};

      line-height: 28.8px;
      font-size: 18px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        font-size: 20px;
      }
    }

    &__token-wrapper {
      position: relative;
    }

    &__token {
      border-radius: 50px;
      background: #24252e;
      border: 1px solid #2d2e39;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 16px;

      &_disabled {
        svg {
          filter: grayscale(100%);
        }
        opacity: 0.6;
      }

      padding: 6px 10px 6px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        padding: 11px 26px 11px 12px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;

      gap: 32px 6px;
      margin-top: 36px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        gap: 16px;
        margin-top: 51px;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
    }

    &__title {
      font-size: 16px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        font-size: 24px;
      }
    }

    &__soon-badge {
      position: absolute;
      top: -21px;
      right: -9px;
      border-radius: 50px;
      background: #ec0b38;
      font-weight: 700;
      border: 5px solid ${({ theme }: { theme: Theme }) => theme.colors.pageBg};
      font-size: 10px;
      text-transform: uppercase;

      right: -16px;
      padding: 2px 7px 4px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        padding: 5px 10px 7px;
        right: -9px;
      }
    }
  }
`;

const tokens = [
  { symbol: 'stETH', soon: false },
  { symbol: 'ETH', soon: false },
  { symbol: 'wETH', soon: false },
  { symbol: 'wstETH', soon: false },
  { symbol: 'WBTC', soon: false },
  { symbol: 'tBTC', soon: false },
  { symbol: 'sUSDe', soon: false },
  { symbol: 'ENA', soon: false },
  { symbol: 'USDC', soon: true },
];

const SupportedTokens: React.FC<Props> = () => {
  const { theme } = useTheme();
  const isTablet = useBreakpointDown(breakpoints.tabletLG);

  return (
    <PageSection title="Supported Tokens">
      <StyledContent theme={theme}>
        <div className="SupportedTokens__description">
          The system is agnostic to assets for restaking and supports any ERC-20 tokens that AVSes
          may need for their security mechanisms.
        </div>

        <div className="SupportedTokens__list">
          {tokens.map(({ symbol, soon }) => (
            <div key={symbol} className="SupportedTokens__token-wrapper">
              <div
                key={symbol}
                className={cn('SupportedTokens__token', { SupportedTokens__token_disabled: soon })}
              >
                <div className="SupportedTokens__icon">
                  <SpriteIcon icon={symbol} size={isTablet ? 2.4 : 4} />
                </div>
                <div className="SupportedTokens__title">{symbol}</div>
              </div>
              {soon && <div className="SupportedTokens__soon-badge">soon</div>}
            </div>
          ))}
        </div>
      </StyledContent>
    </PageSection>
  );
};

export { SupportedTokens };
