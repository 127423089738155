import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { breakpoints } from '@/shared/styles';
import { Theme, useTheme } from '@/services/theme';
import { formatNumber, useBreakpointDown } from '@/shared/utils';
import { restakeLrtStore } from '@/features/restakeLtrs/restakeLtrsStore';
import { Spinner } from '@/shared/components/Spinner';
import { NETWORK_CHAIN, NETWORKS, RESTAKE_SUPPORTED_CHAIN_IDS } from '@/core/config';

import { PageSection } from '../../components/PageSection';
import { ReactComponent as MellowPointsIcon } from './icons/mellowPointsIcon.svg';
import { ReactComponent as SymbioticIcon } from './icons/symbioticIcon.svg';

type Props = unknown;

const StyledContent = styled.div`
  display: flex;
  flex-flow: column wrap;
  font-family: Gramatika;
  white-space: nowrap;

  gap: 12px;
  @media (min-width: ${breakpoints.tabletLG}px) {
    gap: 42px;
  }

  .TvlRewards {
    &__metric {
      display: flex;

      max-width: 100%;
      overflow: hidden;
      flex-direction: column;
      gap: 5px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        align-items: center;
        gap: 42px;
        flex-direction: row;
      }
    }

    &__title {
      font-weight: 700;

      font-size: 10px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        font-size: 16px;
        max-width: 20px;
        transform: rotate(-90deg) translateX(-25px);
      }
    }

    &__item {
      border-radius: 150px;
      border: 1px solid #282933;
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-weight: 400;
      line-height: 1;
      position: relative;
      font-size: clamp(24px, 8vw, 112px);

      padding: 8px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        padding: 43px 60px;
        gap: 40px;
      }

      &_small {
        font-size: 32px;
        flex-direction: column;
        align-items: center;
        margin-left: -100px;

        gap: 1px;
        @media (min-width: ${breakpoints.tabletLG}px) {
          gap: 10px;
        }

        &::after,
        &::before {
          content: '';
          height: 7px;
          width: 1px;
          background: white;
          position: absolute;
          top: 7px;
          left: -6px;
          z-index: 10;

          @media (min-width: ${breakpoints.tabletLG}px) {
            height: 12px;
            width: 2px;
            top: 50%;
            left: 29px;
            transform: translateY(-50%);
          }
        }

        &::before {
          top: 10px;
          width: 7px;
          height: 1px;
          left: -9px;

          @media (min-width: ${breakpoints.tabletLG}px) {
            width: 12px;
            height: 2px;
            left: 24px;
            top: 50%;
          }
        }

        @media (max-width: ${breakpoints.tabletLG}px) {
          width: auto;
          margin-left: 0px;
          border: none;
          padding: 0;
          font-size: 14px;
        }
      }

      &_first-small {
        margin-left: 0;
        &::after,
        &::before {
          display: none;
        }
      }
    }

    &__value {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__description {
      color: ${({ theme }: { theme: Theme }) => theme.colors.text.description};
      font-weight: 300;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0 10px 4px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        padding: 0;
      }

      &_small {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0;

        font-size: 11px;
        @media (min-width: ${breakpoints.tabletLG}px) {
          font-size: 16px;
        }
      }
    }

    &__description-text {
      line-height: 1;
      letter-spacing: -0.5px;
    }

    &__divider {
      width: 1px;
      background-color: #282933;

      height: 40px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        height: 181px;
        margin-top: -27px;
        margin-bottom: -27px;
      }
    }

    &__small-items-container {
      display: flex;
      align-items: center;
      border-radius: 150px;
      justify-content: space-between;
      width: 100%;

      border: 1px solid #282933;
      padding: 16px 27px;
      gap: 10px;
      @media (min-width: ${breakpoints.tabletLG}px) {
        gap: 40px;
        border: none;
        padding: 0;
      }
    }
  }
`;

const StyledWrapper = styled.div`
  margin-top: 65px;
  @media (min-width: ${breakpoints.tabletLG}px) {
    margin-top: 172px;
  }
`;

const TvlRewards: React.FC<Props> = observer(() => {
  const { theme } = useTheme();
  const isTablet = useBreakpointDown(breakpoints.tabletLG);
  const [isLoading, setIsLoading] = useState(false);

  const vaults = restakeLrtStore.vaults.filter(vault =>
    RESTAKE_SUPPORTED_CHAIN_IDS.has(NETWORK_CHAIN[vault.network]),
  );
  useEffect(() => {
    // Load vaults from all networks
    Promise.all(
      // eslint-disable-next-line consistent-return, array-callback-return
      Array.from(RESTAKE_SUPPORTED_CHAIN_IDS).map(chainId => {
        const network = NETWORKS[chainId];
        if (network != null) {
          const vaults = restakeLrtStore.getVaults(network);
          return restakeLrtStore.getVaultsData(vaults);
        }
      }),
    ).then(() => setIsLoading(false));
  }, []);

  const summaryTvl = vaults
    .map(({ id }) => restakeLrtStore.data[id]?.totalValueUSDC)
    .filter((v): v is number => !!v)
    .reduce((prev, cur) => prev + cur, 0);

  const maxAprVault = vaults
    .filter(({ id }) => !Number.isNaN(Number(restakeLrtStore.data[id]?.apr)))
    .sort(
      (a, b) =>
        Number(restakeLrtStore.data[b.id]?.apr || 0) - Number(restakeLrtStore.data[a.id]?.apr || 0),
    );

  const apr = restakeLrtStore.data[maxAprVault[0]?.id]?.apr;

  const totalSymbioticPoints = vaults
    .map(vault => restakeLrtStore.data[vault.id]?.symbioticPoints)
    .filter((amount): amount is number => !!amount)
    .reduce((prev, sum) => sum + prev, 0);

  const totalMellowPoints = vaults
    .map(vault => restakeLrtStore.data[vault.id]?.mellowPoints)
    .filter((amount): amount is number => !!amount)
    .reduce((prev, sum) => sum + prev, 0);

  return (
    <StyledWrapper>
      <PageSection title="TVL & Rewards">
        <StyledContent theme={theme}>
          <div className="TvlRewards__metric">
            <div className="TvlRewards__title">RESTAKED</div>
            <div className="TvlRewards__item">
              <div className="TvlRewards__value">
                {isLoading || !summaryTvl ? <Spinner size="80px" /> : formatNumber(summaryTvl)}
              </div>
              <div className="TvlRewards__divider" />
              <div className="TvlRewards__description">USD</div>
            </div>
          </div>
          <div className="TvlRewards__metric">
            <div className="TvlRewards__title">REWARDS</div>

            <div className="TvlRewards__small-items-container">
              <div className="TvlRewards__item TvlRewards__item_small TvlRewards__item_first-small">
                <div className="TvlRewards__value Tvl Rewards__value_small">
                  {isLoading || !apr ? (
                    <Spinner size="30px" />
                  ) : (
                    <>up to {typeof apr === 'string' ? apr : apr?.toFixed(1)}%</>
                  )}
                </div>
                <div className="TvlRewards__description TvlRewards__description_small">APR</div>
              </div>
              <div className="TvlRewards__item TvlRewards__item_small">
                <div className="TvlRewards__value TvlRewards__value_small">TBD</div>
                <div className="TvlRewards__description TvlRewards__description_small">
                  Restaking APR
                </div>
              </div>
              <div className="TvlRewards__item TvlRewards__item_small">
                <div className="TvlRewards__value TvlRewards__value_small">
                  {isLoading || !totalSymbioticPoints ? (
                    <Spinner size="30px" />
                  ) : (
                    formatNumber(totalSymbioticPoints)
                  )}
                </div>
                <div className="TvlRewards__description TvlRewards__description_small">
                  {!isTablet && <SymbioticIcon />}
                  <span className="TvlRewards__description-text">Symbiotic Points</span>
                </div>
              </div>
              <div className="TvlRewards__item TvlRewards__item_small">
                <div className="TvlRewards__value TvlRewards__value_small">
                  {isLoading || !totalMellowPoints ? (
                    <Spinner size="30px" />
                  ) : (
                    formatNumber(totalMellowPoints)
                  )}
                </div>
                <div className="TvlRewards__description TvlRewards__description_small">
                  {!isTablet && <MellowPointsIcon />}
                  <span className="TvlRewards__description-text">Mellow Points</span>
                </div>
              </div>
            </div>
          </div>
        </StyledContent>
      </PageSection>
    </StyledWrapper>
  );
});

export { TvlRewards };
