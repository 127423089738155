import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';
import { useTheme } from '@/services/theme';

import { ReactComponent as Cyberfund } from './images/cyberfund.svg';
import { ReactComponent as Robot } from './images/robot.svg';
import { ReactComponent as Parafi } from './images/parafi.svg';
import { ReactComponent as Arrigton } from './images/arrington.svg';
import { ReactComponent as Principle } from './images/principle.svg';
import { ReactComponent as Lemniscap } from './images/lemniscap.svg';
import { PageSection } from '../../components/PageSection';
import { ReactComponent as Gearbox } from './images/gearbox.svg';
import { ReactComponent as Pendle } from './images/pendle.svg';
import { ReactComponent as Balancer } from './images/balancer.svg';
import { ReactComponent as Lido } from './images/lido.svg';

const IconWrapper = styled.div`
  width: 94px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.mobileLG}px) {
    width: 186px;
    height: 80px;
  }

  .StyledLink__soon-badge {
    position: absolute;
    top: -16px;
    right: -16px;
    border-radius: 50px;
    background: #ec0b38;
    font-weight: 700;
    border: 5px solid #101115;
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px 10px 7px;
  }
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  font-family: Gramatika;

  border-radius: 16px;
  gap: 16px;
  @media (min-width: ${breakpoints.tabletMD}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .Investors__image {
    border: 1.4px solid rgb(31, 32, 40);
    border-radius: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    height: 88px;
    @media (min-width: ${breakpoints.tabletMD}px) {
      gap: 16px;
      height: 128px;
    }
  }

  .Partners__card {
    display: flex;
    border: 1px solid #1f2028;
    background: #18191f;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    padding: 24px;
    font-size: 16px;
    gap: 24px;
    width: 100%;
    grid-column: 1 / 3;
    @media (min-width: ${breakpoints.tabletMD}px) {
      margin-bottom: 24px;
      grid-column: 1 / 4;
    }
  }

  .Partners__divider {
    width: 1px;
    height: 24px;
    background: #353744;
  }
`;

const StyledWrapper = styled.div`
  padding-bottom: 48px;
  @media (min-width: ${breakpoints.tabletMD}px) {
    padding-bottom: 161px;
  }
`;

const investors = [
  () => (
    <IconWrapper>
      <Cyberfund />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Robot />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Parafi />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Arrigton />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Principle />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Lemniscap />
    </IconWrapper>
  ),
];

const partners = [
  () => (
    <IconWrapper>
      <Pendle />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Balancer />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Gearbox />
    </IconWrapper>
  ),
  // () => (
  //   <IconWrapper>
  //     <Morpho />
  //   </IconWrapper>
  // ),
  // () => (
  //   <IconWrapper>
  //     <Aave />
  //   </IconWrapper>
  // ),
  // () => (
  //   <IconWrapper>
  //     <Velodrome />
  //   </IconWrapper>
  // ),
];

const Investors = () => {
  const { theme } = useTheme();

  return (
    <StyledWrapper>
      <PageSection title="Backed by">
        <StyledContent theme={theme}>
          {investors.map((Image, index) => (
            <div key={index} className="Investors__image">
              <Image key={index} />
            </div>
          ))}
        </StyledContent>
      </PageSection>
    </StyledWrapper>
  );
};

const Partners = () => {
  const { theme } = useTheme();

  return (
    <StyledWrapper>
      <PageSection disableIndent title="Ecosystem Partners">
        <StyledContent theme={theme}>
          <div className="Partners__card">
            <Lido />
            <div className="Partners__divider" />
            <span>Mellow is part of the Lido Alliance</span>
          </div>
          {partners.map((Image, index) => (
            <div key={index} className="Investors__image">
              <Image key={index} />
            </div>
          ))}
        </StyledContent>
      </PageSection>
    </StyledWrapper>
  );
};

export { Investors, Partners };
