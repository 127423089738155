/* eslint-disable jsx-a11y/iframe-has-title */
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { Typography } from '@/shared/components/Typography';
import { NewButton } from '@/shared/components/Button/NewButton';
import { breakpoints } from '@/shared/styles';
import { useAppConfig } from '@/core/AppConfig';
import { grantGAConsent } from '@/shared/utils/ga';
import { isProductionApp } from '@/core/config';

const Container = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0px;
  left: 0px;
  box-sizing: border-box;
  padding: 24px 24px 14px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px 20px 0 0;
  background: ${({ theme }: { theme: Theme }) => theme.colors.newGray3};

  @media (min-width: ${breakpoints.tabletLG}px) {
    width: 100%;
    max-width: 820px;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: row;
    border-radius: 68px;
    padding: 14px 24px 14px 44px;
  }
`;

const StyledLink = styled.a`
  color: ${({ $theme }: { $theme: Theme }) => $theme.colors.primary};
  text-decoration: none;
`;

const CTAsContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding-top: 10px;

  & button {
    justify-content: center;
    width: 100%;
  }

  @media (min-width: ${breakpoints.tabletLG}px) {
    padding-top: 0px;
    width: fit-content;
  }
`;

export const CookieBanner = () => {
  const { theme } = useTheme();
  const [isLoaded, setIsLoaded] = useState(false);
  // synchronize localStorage in prod to reuse `cookiesConsent` from the landing page
  const [isSynced, setIsSynced] = useState(false);
  const { cookiesConsent, updateAppConfig } = useAppConfig();

  const isVisible = cookiesConsent.timestamp <= 0;

  const allow = useCallback(() => {
    grantGAConsent({ marketing: true, analytical: true });
    updateAppConfig({ cookiesConsent: { all: true, timestamp: Math.floor(Date.now() / 1000) } });
  }, [updateAppConfig]);

  const decline = useCallback(() => {
    grantGAConsent({ marketing: false, analytical: false });
    updateAppConfig({ cookiesConsent: { all: false, timestamp: Math.floor(Date.now() / 1000) } });
  }, [updateAppConfig]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    const handler = (message: MessageEvent<any>) => {
      try {
        if (isProductionApp() && message.origin === 'https://mellow.finance') {
          const data = JSON.parse(message.data);
          const localStorage = data?.localStorage;
          if (!localStorage) {
            return false;
          }

          if (localStorage?.cookiesConsent?.timestamp) {
            updateAppConfig({ cookiesConsent: data.localStorage.cookiesConsent });
          }

          setIsSynced(true);
        }

        return true;
      } catch {
        return false;
      }
    };

    if (!isSynced) {
      window.addEventListener('message', handler);
    }

    return () => {
      if (!isSynced) {
        window.removeEventListener('message', handler);
      }
    };
  }, [isSynced, updateAppConfig]);

  const handleLoad = () => {
    try {
      const data = { localStorage: { cookiesConsent } };
      const iframeEl = document.getElementById('xdomain') as HTMLIFrameElement | null;
      if (!iframeEl || !iframeEl.contentWindow) {
        return true;
      }

      iframeEl.contentWindow.postMessage(JSON.stringify(data), 'https://mellow.finance');

      return true;
    } catch {
      return false;
    }
  };

  return (
    <>
      {isVisible && (
        <Container theme={theme}>
          <Typography variant="body1" lineHeight={1.6}>
            Cookies are used to collect anonymous site visitation data to improve the performance of
            website. For more info read{' '}
            <StyledLink
              rel="noreferrer"
              target="_blank"
              href="/Mellow-Privacy-Notice.pdf"
              $theme={theme}
            >
              Privacy Notice
            </StyledLink>
          </Typography>

          <CTAsContainer>
            <NewButton variant="primary" onClick={allow}>
              Allow
            </NewButton>
            <NewButton variant="secondary" onClick={decline}>
              Decline
            </NewButton>
          </CTAsContainer>
        </Container>
      )}

      {isLoaded && isProductionApp() && (
        <iframe
          id="xdomain"
          src="https://mellow.finance/xdomain.html"
          onLoad={handleLoad}
          style={{ width: 0, height: 0, border: 'none', position: 'absolute' }}
        />
      )}
    </>
  );
};
