import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Translated } from '@/services/locale';
import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';

type LinkType = {
  to: string;
  name: string;
  size?: number;
  target?: string;
  title?: string;
  isExternal?: boolean;
  isAnchor?: boolean;
  withBottomBorder?: boolean;
};

type Props = {
  links: LinkType[];
  toggleMenu?: () => void;
  isBurgerMenu?: boolean;
  isVertical?: boolean;
  isMobile?: boolean;
};

const Ul = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between;
  flex-direction: ${(props: { isBurgerMenu: boolean; isVertical: boolean }) =>
    props.isBurgerMenu || props.isVertical ? 'column' : 'row'};
  align-items: ${(props: { isBurgerMenu: boolean }) =>
    props.isBurgerMenu ? 'flex-start' : 'center'};
  padding-left: 20px;

  li {
    margin-left: ${(props: { isBurgerMenu: boolean }) => (props.isBurgerMenu ? '20px' : '0')};
    width: 100%;
  }

  @media (max-width: ${breakpoints.mobileMD}px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
      width: auto;
      margin-right: 16px;
    }
  }

  ${(props: { $isMobile: boolean }) =>
    props.$isMobile &&
    css`
      @media (max-width: ${breakpoints.tabletMD}px) {
        flex-direction: column;

        li {
          width: auto;
          margin-right: 0px;
          margin-left: 16px;
        }
      }
    `}
`;

const Li = styled.li`
  margin-right: ${(props: { isVertical: boolean }) => (props.isVertical ? '0px' : '28px')};
  margin-bottom: ${(props: { isVertical: boolean }) => (props.isVertical ? '24px' : '0px')};

  &:last-child {
    margin-right: 0;
  }

  a {
    position: relative;
    display: inline-block;
    width: ${(props: { isBurgerMenu: boolean }) => (props.isBurgerMenu ? '100%' : 'auto')};
    padding: ${(props: { isBurgerMenu: boolean }) => (props.isBurgerMenu ? '20px 0' : '0')};
    font-family: ${({ theme }: { theme: Theme }) => theme.fonts.noto};
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 400;
    color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
    text-decoration: none;
    text-transform: ${(props: { isBurgerMenu: boolean }) =>
      props.isBurgerMenu ? 'uppercase' : 'none'};
    border-bottom: ${(props: { isBurgerMenu: boolean; theme: Theme }) => {
      const borderStyle = `1px solid ${props.theme.colors.gray4}`;
      return props.isBurgerMenu ? borderStyle : '1px solid transparent';
    }};

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    ${(props: { $isMobile: boolean }) =>
      props.$isMobile &&
      css`
        @media (max-width: ${breakpoints.tabletMD}px) {
          border-bottom: 0px;
          text-transform: none;
          font-size: 2.4rem;
          line-height: 39px;

          &::after {
            opacity: 1;
            background: ${({ theme }: { theme: Theme }) => theme.colors.slateBlue};
            height: 2px;
            bottom: 8px;
          }
        }
      `}
  }
`;

const NavMenu = ({ links, isBurgerMenu, isVertical, toggleMenu, isMobile }: Props) => {
  const { theme } = useTheme();

  const handleHashLinkClick = () => {
    if (isBurgerMenu && toggleMenu) {
      toggleMenu();
    }
  };

  return (
    <Ul isBurgerMenu={isBurgerMenu} isVertical={isVertical} theme={theme} $isMobile={isMobile}>
      {links.map(({ name, to, target, isExternal, isAnchor, withBottomBorder }) => {
        const rel = target === '_blank' ? 'noopener noreferrer' : '';
        return (
          <Li
            key={to}
            isBurgerMenu={isBurgerMenu}
            isVertical={isVertical}
            theme={theme}
            withBottomBorder={withBottomBorder}
            $isMobile={isMobile}
          >
            {isExternal && (
              <a href={to} target={target} rel={rel}>
                <Translated>{name}</Translated>
              </a>
            )}
            {isAnchor && (
              <HashLink to={to} onClick={handleHashLinkClick} smooth>
                <Translated>{name}</Translated>
              </HashLink>
            )}
            {!isExternal && !isAnchor && (
              <Link to={to} target={target} rel={rel}>
                <Translated>{name}</Translated>
              </Link>
            )}
          </Li>
        );
      })}
    </Ul>
  );
};

NavMenu.defaultProps = {
  isBurgerMenu: false,
  isVertical: false,
  toggleMenu: undefined,
  isMobile: false,
};

export type { LinkType };
export { NavMenu };
