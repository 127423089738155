import styled, { css } from 'styled-components';

import { Typography as AppTypography, TypographyProps } from '@/shared/components/Typography';
import { Theme, useTheme } from '@/services/theme';

type Props = Omit<TypographyProps, 'color' | 'weight'> & {
  color?: 'primary' | 'secondary' | 'success';
  weight?: 400 | 500 | 600 | 700;
  children: React.ReactNode;
};

type StyledProps = Props & {
  theme: Theme;
};

const StyledTypography = styled(AppTypography)`
  ${({ color, size, theme, variant = 'span' }: StyledProps) => {
    const variantStyles = {
      h1: css`
        color: ${theme.colors.text.primary};
        font-family: Gramatika;
        font-size: clamp(2.4rem, 3.75vw, 4.8rem);
        font-weight: 500;
        line-height: 1.5;
      `,
      h2: css`
        color: ${theme.colors.text.primary};
        font-family: Gramatika;
        font-size: 48px;
        font-weight: 400;
        line-height: 1.5;
      `,
      h3: css`
        color: ${theme.colors.text.primary};
        font-family: Gramatika;
        font-size: 2rem;
        font-weight: 600;
      `,
      h4: css``,
      h5: css``,
      h6: css``,
      body1: css`
        color: ${theme.colors.gray13};
        font-family: Gramatika;
        font-size: clamp(1.4rem, calc(1.2rem + 0.3125vw), 1.6rem);
        line-height: 1.6;
      `,
      body2: css`
        color: ${theme.colors.gray13};
        font-family: Gramatika;
        font-size: clamp(1.2rem, calc(1rem + 0.3125vw), 1.4rem);
        line-height: 1.6;
      `,
      label: css``,
      nav: css``,
      span: css``,
    };

    const textColor = {
      primary: theme.colors.text.primary,
      secondary: theme.colors.gray13,
      success: theme.colors.lighterSuccess,
    };

    const styleOverrides = css`
      ${color && `color: ${textColor[color]};`}
      ${size !== undefined && `font-size: ${size}rem;`}
    `;

    return css`
      ${variantStyles[variant]};
      ${styleOverrides};
    `;
  }};
`;

const Typography: React.FC<Props> = ({ children, ...props }) => {
  const { theme } = useTheme();

  return (
    <StyledTypography {...props} theme={theme}>
      {children}
    </StyledTypography>
  );
};

export { Typography };
