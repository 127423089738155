import styled from 'styled-components';

import { Theme, useTheme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';
import { SpriteIcon } from '@/shared/components/SpriteIcon';

import { ReactComponent as Chainsecurity } from './chainsecurity.svg';
import { ReactComponent as Sherlock } from './sherlock.svg';
import { ReactComponent as Statemind } from './statemind.svg';
import { PageSection } from '../../components/PageSection';

const FeaturesStyled = styled.section`
  display: grid;
  gap: 16px;

  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints.mobileXL}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoints.tabletMD}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .Audits__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #1f2028;
    background: #18191f;
    border-radius: 16px;
    line-height: 1.4;
    padding: 32px 0 40px;
    gap: 16px 10px;

    svg {
      transform: scale(0.76);
    }
  }
`;

const StyledLink = styled.a`
  color: ${({ $theme }: { $theme: Theme }) => $theme.colors.text.primary};
  font-family: ${({ $theme }: { $theme: Theme }) => $theme.fonts.main};
  line-height: 1.6;
  text-decoration: underline solid transparent;
  text-underline-offset: 0.35em;
  transition: text-decoration-color 300ms;
  font-size: 14px;
  text-decoration-color: ${({ $theme }: { $theme: Theme }) => $theme.colors.gray15};
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ $disabled }: { $disabled: boolean }) =>
    $disabled &&
    `
      opacity: 0.5;
      cursor: default;
  `}

  &:focus,
  &:hover {
    text-decoration-color: ${({ $theme }: { $theme: Theme }) => $theme.colors.text.primary};
  }
`;

const Audits = () => {
  const { theme } = useTheme();
  return (
    <PageSection title="Audits">
      <FeaturesStyled theme={theme}>
        <div className="Audits__card">
          <Chainsecurity />
          <StyledLink
            href="https://github.com/mellow-finance/mellow-lrt/blob/fixes/audit-sherlock-fixes/audits/202408_ChainSecurity/ChainSecurity_Mellow_Finance_Mellow_LRT_audit.pdf"
            target="_blank"
            rel="noreferrer"
            $theme={theme}
          >
            {/* Chainsecurity Report */}
            Chainsecurity Report
            <SpriteIcon icon="external-link" color="#707082" size={1.4} />
          </StyledLink>
        </div>
        <div className="Audits__card">
          <Statemind />
          <StyledLink
            href="https://github.com/mellow-finance/mellow-lrt/blob/main/audits/202405_Statemind/Mellow%20LRT%20Final%20report.pdf"
            target="_blank"
            rel="noreferrer"
            $theme={theme}
          >
            Statemind Report
            <SpriteIcon icon="external-link" color="#707082" size={1.4} />
          </StyledLink>
        </div>
        <div className="Audits__card">
          <Sherlock />
          <StyledLink
            href="https://github.com/mellow-finance/mellow-lrt/blob/fixes/audit-sherlock-fixes/audits/202406_Sherlock/Mellow%20Modular%20LRTs%20Audit%20Report.pdf"
            target="_blank"
            rel="noreferrer"
            $theme={theme}
          >
            Sherlock Report
            <SpriteIcon icon="external-link" color="#707082" size={1.4} />
          </StyledLink>
        </div>
      </FeaturesStyled>
    </PageSection>
  );
};

export { Audits };
