import React, { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import { appTranslations } from '@/shared/translations/app';
import { landingTranslations } from '@/shared/translations/landing';
import { useLocale } from '@/services/locale';

export const TranslationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { locale } = useLocale();
  const translations =
    process.env.REACT_APP_ENTRY === 'app' ? appTranslations : landingTranslations;

  const onError = () => {};

  return (
    <IntlProvider
      locale={locale}
      messages={translations[locale]}
      onError={onError}
      textComponent={React.Fragment}
    >
      {children}
    </IntlProvider>
  );
};
