import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';
import { Theme, useTheme } from '@/services/theme';
import { SpriteIcon } from '@/shared/components/SpriteIcon';

import { PageSection } from '../../components/PageSection';
import { ReactComponent as HowItWorksImage } from './howItWorks.svg';

type Props = unknown;

const StyledContent = styled.div`
  font-family: Gramatika;

  .HowItWorks {
    &__wrapper {
      display: flex;
      flex-flow: column wrap;
      margin-top: 30px;
      justify-content: space-between;

      gap: 36px;
      @media (min-width: ${breakpoints.tabletXL}px) {
        gap: 16px;
        flex-flow: row nowrap;
      }
    }

    &__description {
      color: ${({ theme }: { theme: Theme }) => theme.colors.text.description};
      font-size: 18px;
      line-height: 28.8px;
      @media (min-width: ${breakpoints.tabletXL}px) {
        font-size: 20px;
      }
    }

    &__points {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding-left: 36px;
      gap: 26px;
      @media (min-width: ${breakpoints.tabletXL}px) {
        padding-left: 0;
        gap: 72px;
        padding-right: 26px;
      }
    }

    &__point {
      display: flex;
      flex-direction: column;
    }

    &__point-title {
      position: relative;
      font-weight: 700;
      line-height: 1;
      display: flex;
      gap: 16px;
      align-items: center;

      min-height: 50px;
      font-size: 18px;
      @media (min-width: ${breakpoints.tabletXL}px) {
        font-size: 24px;
      }
    }

    &__point-subtitle {
      color: ${({ theme }: { theme: Theme }) => theme.colors.text.description};
      max-width: 384px;

      font-size: 14px;
      margin-top: -10px;
      @media (min-width: ${breakpoints.tabletXL}px) {
        margin-top: 0;
        font-size: 16px;
      }
    }

    &__point-subtitle-badge {
      margin-top: 0px;
      @media (min-width: ${breakpoints.tabletXL}px) {
        margin-top: 0;
      }
    }

    &__circle {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      left: -40px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__lrtETH {
      border-radius: 50px;
      padding: 7px 10px;
      background: #24252e;
      border: 1px solid #2d2e39;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400;
    }

    &__image {
      overflow: hidden;
      max-width: 100%;

      svg {
        max-height: 396px;
        @media (min-width: ${breakpoints.tabletXL}px) {
          max-height: unset;
        }
      }
    }
  }
`;

const HowItWorks: React.FC<Props> = () => {
  const { theme } = useTheme();

  return (
    <PageSection title="How It Works">
      <StyledContent theme={theme}>
        <div className="HowItWorks__description">
          The system enables permissionless LRT creation allowing for unlimited risk profiles by
          working with diverse set of AVSes and operators.
        </div>

        <div className="HowItWorks__wrapper">
          <div className="HowItWorks__image">
            <HowItWorksImage />
          </div>
          <div className="HowItWorks__points">
            <div className="HowItWorks__point">
              <div className="HowItWorks__point-title">
                <div className="HowItWorks__circle" style={{ background: '#3765D7' }} />
                Issue your LRT
                <div className="HowItWorks__lrtETH">
                  <SpriteIcon
                    style={{ background: '#3765D7', borderRadius: '50%' }}
                    icon="lrtETH"
                    size={2.6}
                  />
                  <span>lrtETH</span>
                </div>
              </div>
              <div className="HowItWorks__point-subtitle HowItWorks__point-subtitle-badge">
                Curate LRTs for an open market.
              </div>
            </div>
            <div className="HowItWorks__point">
              <div className="HowItWorks__point-title">
                <div className="HowItWorks__circle" style={{ background: '#EF0B38' }} />
                Be in Control
              </div>
              <div className="HowItWorks__point-subtitle">
                Determine risks, choose AVSes profile, design your framework.
              </div>
            </div>
            <div className="HowItWorks__point">
              <div className="HowItWorks__point-title">
                <div className="HowItWorks__circle" style={{ background: '#6B6E7C' }} />
                Distribute easily
              </div>
              <div className="HowItWorks__point-subtitle">
                Integrations with DeFI protocols for asset utilization and liquid markets, designed
                for composability.
              </div>
            </div>
          </div>
        </div>
      </StyledContent>
    </PageSection>
  );
};

export { HowItWorks };
