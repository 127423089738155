import styled, { css } from 'styled-components';

import { useTheme, Theme } from '@/services/theme';

import { Typography } from '../Typography';

type Props = {
  title: string;
  text: string;
  url?: string;
  background: string;
};

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  min-height: 100px;

  border-radius: 16px;
  text-decoration: none;
  width: 100%;
  transition: all 250ms;
  background: ${({ $background }: { $background: string }) => $background};
  padding: 26px 32px 32px;
  gap: 16px;
  position: relative;

  &:hover {
    ${({ as, theme }: { as: 'a' | 'div'; theme: Theme }) =>
      as === 'a' &&
      css`
        box-shadow: 0 0 8px ${theme.colors.shadow1};
        transform: scale(1.01);
      `};
  }

  .StyledLink__description {
    line-height: 21px;
    color: ${({ theme }: { theme: Theme }) => theme.colors.white};

    font-size: 15px;
    padding-right: initial;
  }

  .arrow {
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    position: relative;

    width: 11px;
    height: 11px;

    &::after {
      content: '';
      height: 2px;
      width: 12px;
      background-color: #fff;
      transform: rotate(-45deg) translate(-1px, 3px);
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

const StyledTitle = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Gramatika;
  font-size: 24px;
  font-weight: 400;
`;

const CardLink: React.FC<Props> = ({ title, text, url, background }) => {
  const { theme } = useTheme();

  return (
    <StyledLink
      as={url ? 'a' : 'div'}
      href={url}
      target="_blank"
      theme={theme}
      $background={background}
    >
      <StyledTitle variant="h3">
        {title} <span className="arrow" />
      </StyledTitle>
      <span className="StyledLink__description">{text}</span>
    </StyledLink>
  );
};

export { CardLink };
