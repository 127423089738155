import styled from 'styled-components';
import { useEffect } from 'react';

import { useTheme, Theme } from '@/services/theme';
import { landingHeaderLinks } from '@/shared/mocks/navigationLinks';
import { SpriteIcon } from '@/shared/components/SpriteIcon';

import { NavMenu } from '../../../NavMenu';

type Props = {
  isMenuOpen: boolean;
  hidden: boolean;
  toggleMenu: () => void;
};

const Wrap = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  display: ${(props: { hidden: boolean }) => (props.hidden ? 'none' : 'block')};
  overflow: hidden;
  width: 100%;
  max-width: 0;
  min-height: 100vh;
  max-width: ${(props: { $isOpen: boolean }) => (props.$isOpen ? '100%' : 'auto')};
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background.dark};
  transition: 0.25s;
  padding-top: 80px;
`;

const StyledSocialWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledIconLink = styled.a`
  margin-right: 40px;
  transition:
    opacity 0.3s ease,
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #2a2c33;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  &:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const StyledClose = styled.div`
  border-radius: 50%;
  border: 1px solid #2a2c33;
  width: 40px;
  height: 40px;
  padding: 11.5px;
  position: absolute;
  top: 18px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileMenu = ({ isMenuOpen = false, hidden = true, toggleMenu }: Props) => {
  const { theme } = useTheme();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isMenuOpen]);

  return (
    <Wrap theme={theme} $isOpen={isMenuOpen} hidden={hidden}>
      <NavMenu links={landingHeaderLinks.nav} toggleMenu={toggleMenu} isBurgerMenu isMobile />

      <StyledSocialWrap>
        {landingHeaderLinks.social.map(item => {
          const rel = item.target === '_blank' ? 'noopener noreferrer' : '';

          return (
            <StyledIconLink
              key={item.name}
              href={item.to}
              target={item.target}
              rel={rel}
              title={item.title}
            >
              <SpriteIcon icon={item.name} color="#fff" size={item.size} />
            </StyledIconLink>
          );
        })}
      </StyledSocialWrap>

      <StyledClose type="button" onClick={toggleMenu}>
        <SpriteIcon icon="x-cross" color="#fff" size={1.2} />
      </StyledClose>
    </Wrap>
  );
};

export { MobileMenu };
