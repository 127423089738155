export const collectorAbiEth = [
  {
    inputs: [
      { internalType: 'address', name: 'wsteth_', type: 'address' },
      { internalType: 'address', name: 'weth_', type: 'address' },
      { internalType: 'address', name: 'steth_', type: 'address' },
      { internalType: 'contract IAggregatorV3', name: '_wethToUSDOracle', type: 'address' },
      { internalType: 'address', name: 'owner_', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { inputs: [], name: 'MathOverflowedMulDiv', type: 'error' },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'address[]', name: 'vaults', type: 'address[]' },
    ],
    name: 'collect',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'address[]', name: 'underlyingTokens', type: 'address[]' },
          { internalType: 'uint256[]', name: 'underlyingAmounts', type: 'uint256[]' },
          { internalType: 'uint8[]', name: 'underlyingTokenDecimals', type: 'uint8[]' },
          { internalType: 'uint128[]', name: 'depositRatiosX96', type: 'uint128[]' },
          { internalType: 'uint128[]', name: 'withdrawalRatiosX96', type: 'uint128[]' },
          { internalType: 'uint256[]', name: 'pricesX96', type: 'uint256[]' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceETH', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceWSTETHD18', type: 'uint256' },
          { internalType: 'bool', name: 'shouldCloseWithdrawalRequest', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
              { internalType: 'bytes32', name: 'tokensHash', type: 'bytes32' },
              { internalType: 'uint256[]', name: 'minAmounts', type: 'uint256[]' },
              { internalType: 'uint256', name: 'deadline', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct IVault.WithdrawalRequest',
            name: 'withdrawalRequest',
            type: 'tuple',
          },
        ],
        internalType: 'struct Collector.Response[]',
        name: 'responses',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'priceX96', type: 'uint256' },
      { internalType: 'address', name: 'token', type: 'address' },
    ],
    name: 'convertBaseTokenPriceToWethPrice',
    outputs: [{ internalType: 'uint256', name: 'wethPriceX96', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'convertWethToUSDC',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
      { internalType: 'address', name: 'vault', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'fetchDepositAmounts',
    outputs: [
      {
        components: [
          { internalType: 'bool', name: 'isDepositPossible', type: 'bool' },
          { internalType: 'bool', name: 'isDepositorWhitelisted', type: 'bool' },
          { internalType: 'uint256[]', name: 'ratiosD18', type: 'uint256[]' },
          { internalType: 'address[]', name: 'tokens', type: 'address[]' },
          { internalType: 'uint256', name: 'expectedLpAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'expectedLpAmountUSDC', type: 'uint256' },
          { internalType: 'uint256[]', name: 'expectedAmounts', type: 'uint256[]' },
          { internalType: 'uint256[]', name: 'expectedAmountsUSDC', type: 'uint256[]' },
        ],
        internalType: 'struct Collector.FetchDepositAmountsResponse',
        name: 'r',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'vault', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'fetchDepositWrapperParams',
    outputs: [
      { internalType: 'bool', name: 'isDepositPossible', type: 'bool' },
      { internalType: 'bool', name: 'isDepositorWhitelisted', type: 'bool' },
      { internalType: 'bool', name: 'isWhitelistedToken', type: 'bool' },
      { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'depositValueUSDC', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
      { internalType: 'address', name: 'vault', type: 'address' },
    ],
    name: 'fetchWithdrawalAmounts',
    outputs: [
      { internalType: 'uint256[]', name: 'expectedAmounts', type: 'uint256[]' },
      { internalType: 'uint256[]', name: 'expectedAmountsUSDC', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'users', type: 'address[]' },
      { internalType: 'address[]', name: 'vaults', type: 'address[]' },
    ],
    name: 'multiCollect',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'uint256', name: 'balance', type: 'uint256' },
          { internalType: 'address[]', name: 'underlyingTokens', type: 'address[]' },
          { internalType: 'uint256[]', name: 'underlyingAmounts', type: 'uint256[]' },
          { internalType: 'uint8[]', name: 'underlyingTokenDecimals', type: 'uint8[]' },
          { internalType: 'uint128[]', name: 'depositRatiosX96', type: 'uint128[]' },
          { internalType: 'uint128[]', name: 'withdrawalRatiosX96', type: 'uint128[]' },
          { internalType: 'uint256[]', name: 'pricesX96', type: 'uint256[]' },
          { internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupply', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceETH', type: 'uint256' },
          { internalType: 'uint256', name: 'userBalanceUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'totalValueBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyUSDC', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyWSTETH', type: 'uint256' },
          { internalType: 'uint256', name: 'maximalTotalSupplyBaseToken', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceETHD18', type: 'uint256' },
          { internalType: 'uint256', name: 'lpPriceWSTETHD18', type: 'uint256' },
          { internalType: 'bool', name: 'shouldCloseWithdrawalRequest', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'lpAmount', type: 'uint256' },
              { internalType: 'bytes32', name: 'tokensHash', type: 'bytes32' },
              { internalType: 'uint256[]', name: 'minAmounts', type: 'uint256[]' },
              { internalType: 'uint256', name: 'deadline', type: 'uint256' },
              { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
            ],
            internalType: 'struct IVault.WithdrawalRequest',
            name: 'withdrawalRequest',
            type: 'tuple',
          },
        ],
        internalType: 'struct Collector.Response[][]',
        name: 'responses',
        type: 'tuple[][]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'oracles',
    outputs: [{ internalType: 'address', name: 'oracle', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'tokens', type: 'address[]' },
      { internalType: 'address[]', name: 'oracles_', type: 'address[]' },
    ],
    name: 'setOracles',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'owner_', type: 'address' }],
    name: 'setOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'steth',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'weth',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'wethOracle',
    outputs: [{ internalType: 'contract IAggregatorV3', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'wsteth',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const;
