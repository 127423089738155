import sprite from '@/shared/assets/sprite.svg';

type Props = {
  className?: string;
  color?: string;
  icon: string;
  size?: number | string;
  style?: React.CSSProperties;
};

const SpriteIcon: React.VFC<Props> = ({ className = '', color = '', icon, size = 3.2, style }) => (
  <svg
    style={style}
    className={className}
    href={sprite}
    viewBox="0 0 32 32"
    height={typeof size === 'number' ? `${size}rem` : size}
    width={typeof size === 'number' ? `${size}rem` : size}
    fill={color}
  >
    <use xlinkHref={`${sprite}#${icon.toLowerCase()}`} />
  </svg>
);

export { SpriteIcon };
