import { format, parseISO } from 'date-fns';
import { BigNumber } from 'ethers';
import { formatUnits, isAddress } from 'ethers/lib/utils';
import { repeat } from 'ramda';

export function zeroWithPrecision(precision: number): string {
  return `0.${repeat('0', precision).join('')}`;
}

export function discardFractionTo(number: string, count: number = 2): string {
  return number
    .toString()
    .replace(
      new RegExp(`(?<int>\\d+?)\\.(?<fract>\\d{${count}})\\d+?(?<degree>e.\\d+?)?$`),
      '$<int>.$<fract>$<degree>',
    );
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

/**
 * Join list of words with ',' and 'and'
 */
export const listFormat = (list_: string[]) => {
  const list = list_.filter(word => !!word);
  if (list.length === 1) {
    return list[0];
  }

  const commaSeparated = list.slice(0, list.length - 1);
  return `${commaSeparated.join(', ')} and ${list[list.length - 1]}`;
};

export const toFixedNumber = (val: number, precision: number): number => {
  const factor = 10 ** precision;
  return Math.floor(val * factor) / factor;
};

export const formatToFirstNonZero = (number: number, additionalPrecession?: number) => {
  if (number === 0) return '0';

  if (Math.abs(number) < 0.00001) {
    return '<0.00001';
  }

  if (Math.abs(number) < 0.1) {
    return String(toFixedNumber(number, 5));
  }

  const precession =
    number < 1
      ? additionalPrecession ??
        Math.abs(Number(number.toExponential().split('e').pop())) + (additionalPrecession || 0)
      : additionalPrecession || 0;
  const correctedPrecession = precession > 20 ? 20 : precession;

  const [integer, fractional] = number.toFixed(correctedPrecession || 0).split('.');

  const filteredFractional = fractional?.replace(/0+$/, '');

  const filtered = `${integer}${filteredFractional ? `.${filteredFractional}` : ''}`;

  return filtered;
};

export const getRidOfExp = (number: number) => {
  if (number > 1) {
    return String(number);
  }
  const total = number.toExponential().split('e');
  const precession = Math.abs(Number(total.pop()));

  if (precession <= 0) {
    return String(number);
  }

  const zeroes = Array(precession - 1)
    .fill('0')
    .join('');
  const amount = total.shift()?.replace('.', '');
  return `0.${zeroes}${amount}`;
};

export const toFixedPretty = (val: number, precision: number): string =>
  toFixedNumber(val, precision).toLocaleString('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

export const toFixed = (val: number, precision: number): string =>
  toFixedNumber(val, precision).toFixed(precision);

export const addNumberSuffix = (value: number, suffix: string, precision: number = 0) =>
  value > 100
    ? String(formatToFirstNonZero(Math.round(value))) + suffix
    : formatToFirstNonZero(value, precision) + suffix;

export const toExponential = (value: number) => {
  const valueString = value.toFixed(0);
  const isExpString = /^\d\.\d+/.test(valueString);
  if (isExpString) {
    return valueString.replace(/^(\d)(\.)(\d{0,3})\d+?e\+(\d+)$/, '$1.$3E+$4');
  }
  const pow = Math.floor((valueString.length - 1) / 3) * 3;
  return valueString.replace(/^(\d)(\d{3})(.+)$/, `$1E+${pow}`);
};

export const formatNumber = (value: number, precision?: number): string => {
  if (value < 0) return `-${formatNumber(-value, precision)}`;
  if (value === 0) return '0';
  if (!Number.isFinite(value)) return '0';
  if (value > 1e15) return toExponential(value);
  if (value > 1e12) return addNumberSuffix(value / 1e9, 'Q', precision);
  if (value > 1e9) return addNumberSuffix(value / 1e9, 'B', precision);
  if (value > 1e6) return addNumberSuffix(value / 1e6, 'M', precision);
  if (value > 1e4) return addNumberSuffix(value / 1e3, 'K', precision);

  if (value > 100) return formatToFirstNonZero(value, precision || 0);
  if (value > 10) return formatToFirstNonZero(value, precision || 2);
  if (value > 1) return formatToFirstNonZero(value, precision || 5);
  if (value < 1) return formatToFirstNonZero(value, precision || 5);

  return formatToFirstNonZero(value);
};

export const pad = (num: number, size: number) => {
  let nums = num.toString();
  while (nums.length < size) nums = `0${nums}`;
  return nums;
};

export const localizeNumber = (
  n: number | undefined,
  replacer: string,
  maximumFractionDigits: number = 1,
) =>
  n
    ? n.toLocaleString('en-US', {
        maximumFractionDigits,
      })
    : replacer;

export const formatDate = (dateString: string) => {
  const date = parseISO(dateString);
  return format(date, "d MMM yyyy 'at' H:m 'UTC'");
};

export const shortenAddress = (address: unknown, startLength: number = 4, endLength: number = 4) =>
  typeof address === 'string' && isAddress(address)
    ? `${address.slice(0, startLength)}...${address.slice(-endLength)}`
    : '';

export const formatWithLength = (value: BigNumber, decimals: number, maxLength: number = 5) => {
  const n = +formatUnits(value, decimals);

  const integralPart = Math.round(n);
  const integralLength = String(integralPart).length;

  if (integralLength >= maxLength) return integralPart.toLocaleString();

  const precision = maxLength - integralLength;

  const fractionalPart = n % 1;
  const fractionalWithPrecision = +fractionalPart.toFixed(precision);

  return (integralPart + fractionalWithPrecision).toLocaleString('en-US', {
    maximumFractionDigits: precision,
  });
};

export const clampBn = (value: BigNumber, min: BigNumber, max?: BigNumber) => {
  if (value.lt(min)) {
    return min;
  }

  if (max && value.gt(max)) {
    return max;
  }

  return value;
};

export const formatBalance = (balance: BigNumber, decimals: number) => {
  const balanceNumber = Number(balance.toString()) / 10 ** decimals;

  if (Math.abs(balanceNumber) < 0.000001 && balanceNumber > 0) {
    const newValue = formatToFirstNonZero(Number(balanceNumber.toFixed(5)));
    return newValue.search(/[^0.]/) ? newValue : `${newValue.slice(-1)}1`;
  }

  return balanceNumber > 1 && balanceNumber < 100
    ? balanceNumber.toFixed(2)
    : formatToFirstNonZero(balanceNumber, 3);
};

export const formatNumberUsd = (value: number) => {
  if (value < 0.01) return '0.01';
  return value < 100 ? value.toFixed(2) : formatNumber(value);
};
