import styled from 'styled-components';

import { useTheme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';

import { Typography } from '../Typography';

type Props = {
  children: React.ReactNode;
  className?: string;
  id?: string;
  title: string;
  link?: {
    text: string;
    title: string;
    url: string;
  };
  disableIndent?: boolean;
};

const StyledSection = styled.section`
  display: grid;
  grid-template: 'title' auto 'content' auto 'link' auto / 100%;
  margin: 0 auto;
  max-width: 1136px;
  scroll-margin-top: 11rem;
  align-items: center;

  padding: ${({ $disableIndent }: { $disableIndent: boolean }) =>
    $disableIndent ? '0' : '0 0 48px'};
  gap: 14px;
  @media (min-width: ${breakpoints.mobileXL}px) {
    gap: 36px;
    padding: ${({ $disableIndent }: { $disableIndent: boolean }) =>
      $disableIndent ? '0' : '0 0 161px'};
    grid-template: 'title link' auto 'content content' auto / 1fr auto;
  }
`;

const StyledTitle = styled(Typography)`
  grid-area: title;
  font-weight: 400;
  font-size: 24px;
  font-family: Gramatika;

  @media (min-width: ${breakpoints.mobileXL}px) {
    font-size: 48px;
    letter-spacing: -1.6px;
  }
`;

const StyledLink = styled.a`
  grid-area: link;

  align-items: center;
  gap: 1rem;

  background-color: #ffffff;
  border-radius: 36px;
  padding: 13px 23px;
  text-decoration: none;
  color: #101115;
  font-weight: 700;
  font-size: 13px;
  max-height: 44px;

  display: none;
  @media (min-width: ${breakpoints.mobileXL}px) {
    display: flex;
  }
`;

const StyledContent = styled.div`
  grid-area: content;
`;

const PageSection: React.FC<Props> = ({ children, className, id, link, title, disableIndent }) => {
  const { theme } = useTheme();

  return (
    <StyledSection className={className} id={id} $disableIndent={disableIndent}>
      <StyledTitle variant="h2">{title}</StyledTitle>
      {link && (
        <StyledLink
          href={link.url}
          rel="noreferrer"
          target="_blank"
          title={link.title}
          theme={theme}
        >
          {link.text}
        </StyledLink>
      )}
      <StyledContent>{children}</StyledContent>
    </StyledSection>
  );
};

export { PageSection };
